import React from 'react';
import {
  MUTATION_REMOVE_SESSION,
  S_QUERY_GET_SESSIONS_BY_ENTITY_ID,
  QUERY_GET_SESSIONS_OF_SPORTER
} from 'services/aws/session-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from './ConfirmMutationButton';
import messages from 'messages';

const RemoveSessionButton = ({
  entityId,
  sessionId,
  children,
  onComplete,
  sporterId,
  ...props
}) => {
  let refetchQueries = [
    {
      query: S_QUERY_GET_SESSIONS_BY_ENTITY_ID,
      variables: { entityId }
    }
  ];
  if (sporterId) {
    refetchQueries = [
      {
        query: QUERY_GET_SESSIONS_OF_SPORTER,
        variables: { personId: sporterId, entityId }
      }
    ];
  }
  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_REMOVE_SESSION}
      refetchQueries={refetchQueries}
      confirmTitle={messages.modalConfirmRemoveSessionTitle}
      confirmMessage={messages.modalConfirmRemoveSessionMessage}
      variables={{
        testSessionId: sessionId
      }}
      update={(cache, { data: { archiveTestSession } }) => {
        if (archiveTestSession) {
          if (onComplete) onComplete();
        }
      }}
      dataQa={'remove-session'}
    >
      <Icon id="delete" />
      {children}
    </ConfirmMutationButton>
  );
};

export default RemoveSessionButton;
