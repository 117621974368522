import { useContext, useEffect, useState } from 'react';
import { withRouter, generatePath, useHistory } from 'react-router-dom';
import ListItemLabelWrapper from 'components/list/list-item/ListItemLabelWrapper';
import ListItemLabel from 'components/list/list-item/ListItemLabel';
import ListItemStatus from 'components/list/list-item/ListItemStatus';
import ListItem from 'components/list/list-item/ListItem';
import Button from 'components/button/Button';
import { QUERY_GET_BENCHMARKS } from 'services/aws/benchmark-query';
import {
  ROUTE_SETTINGS,
  ROUTE_SETTINGS_BENCHMARKS_BENCHMARK
} from 'routes/RouteList';
import Panel from 'components/panel/Panel';
import PanelHeader from 'components/panel/PanelHeader';
import PanelTitleWrapper from 'components/panel/panel-title/PanelTitleWrapper';
import PanelTitle from 'components/panel/panel-title/PanelTitle';
import PanelSubtitle from 'components/panel/panel-title/PanelSubtitle';
import { FormattedMessage } from 'react-intl';
import Icon from 'components/icon/Icon';
import PanelTools from 'components/panel/PanelTools';
import Breadcrumbs from 'components/breadcrumbs/Breadcrumbs';
import Breadcrumb from 'components/breadcrumbs/Breadcrumb';
import List from 'components/list/List';
import ListBody from 'components/list/ListBody';
import BenchmarkModal, {
  BENCHMARK_UPDATE_TYPE
} from 'containers/partials/modals/BenchmarkModal';
import ListHeader from 'components/list/ListHeader';
import ListColumnTitle from 'components/list/ListColumnTitle';
import RetryPanel from 'containers/partials/error-boundary/RetryPanel';
import messages from 'messages';
import Badge from 'components/badge/Badge';
import Loader from 'components/loader/Loader';
import { getRawGroups } from 'utils/tests';
import { StoreContext } from 'index';
import { useQuery } from '@apollo/client';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';

const Benchmarks = ({ benchmarkId }) => {
  const ability = useAbility(AbilityContext);
  const {
    authStore: {
      user: { rootEntityId }
    }
  } = useContext(StoreContext);
  const { push } = useHistory();

  const [benchmarks, setBenchmarks] = useState([]);
  const [groupedBenchmarks, setGroupedBenchmarks] = useState([]);
  const [modalData, setModalData] = useState(false);

  const { loading, error, data } = useQuery(QUERY_GET_BENCHMARKS, {
    variables: { entityId: rootEntityId }
  });

  useEffect(() => {
    if (data?.getBenchmarks) {
      setBenchmarks(data.getBenchmarks);
      setGroupedBenchmarks(getRawGroups(data.getBenchmarks));
    }
  }, [data]);

  return (
    <Panel>
      <PanelHeader borderBottom>
        <Breadcrumbs>
          <Breadcrumb onClick={() => push(ROUTE_SETTINGS)}>
            <FormattedMessage {...messages.breadcrumbSettings} />
          </Breadcrumb>
        </Breadcrumbs>
        <PanelTitleWrapper>
          <PanelTitle>
            <FormattedMessage {...messages.benchmarksTitle} />
          </PanelTitle>
          <PanelSubtitle>
            <FormattedMessage {...messages.benchmarksSubtitle} />
          </PanelSubtitle>
        </PanelTitleWrapper>
        <PanelTools>
          {ability.can('create', 'Benchmarks') && (
            <Button
              rounded
              primary
              onClick={() => setModalData({ type: BENCHMARK_UPDATE_TYPE.ADD })}
            >
              <Icon id="add-white" />
              <FormattedMessage {...messages.benchmarksAddBenchmarkButton} />
            </Button>
          )}
        </PanelTools>
      </PanelHeader>

      {loading && <Loader />}
      {error && <RetryPanel />}

      <>
        {modalData && (
          <BenchmarkModal
            {...modalData}
            existingNames={benchmarks.map(b => b.title.toLowerCase())}
            onClose={() => setModalData(null)}
          />
        )}
        {groupedBenchmarks.map(g => (
          <List key={g.id}>
            {g.label && (
              <ListHeader oneTitle>
                <ListColumnTitle>{g.label}</ListColumnTitle>
              </ListHeader>
            )}
            <ListBody>
              {g.children.map(bench => (
                <BenchmarkListItem
                  key={bench.id}
                  benchmark={bench}
                  push={push}
                  active={benchmarkId === bench.id}
                />
              ))}
            </ListBody>
          </List>
        ))}
      </>
    </Panel>
  );
};

export default withRouter(Benchmarks);

const BenchmarkListItem = ({
  active,
  benchmark,
  benchmark: {
    id,
    title,
    draft,
    person = { firstname: '', lastname: '' },
    version,
    entity = { name: '' }
  }
}) => {
  const { push } = useHistory();
  const creator =
    benchmark.default && entity
      ? entity.name
      : !benchmark.default && person
        ? `${person.firstname} ${person.lastname}`
        : '';
  return (
    <ListItem
      onClick={() =>
        push(
          generatePath(ROUTE_SETTINGS_BENCHMARKS_BENCHMARK, {
            benchmarkId: id
          })
        )
      }
      active={active}
      cta={!!draft}
      noMaxHeight
    >
      <ListItemStatus statusIcon="benchmarks" />
      <ListItemLabelWrapper>
        <ListItemLabel label style={{ flex: '0 0 auto' }}>
          {title}
          <Badge lighterFont>v{version}</Badge>
        </ListItemLabel>
        <ListItemLabel info middleColumn>
          {creator}
        </ListItemLabel>
        <ListItemLabel info>
          {draft && (
            <Button rounded accented style={{ lineHeight: 1, margin: '2px 0' }}>
              <FormattedMessage {...messages.benchmarksBenchmarkDraft} />
            </Button>
          )}
        </ListItemLabel>
      </ListItemLabelWrapper>
    </ListItem>
  );
};
