import { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/modal/Modal';
import { Tab, TabList, TabPanel } from 'react-tabs';
import { Tabs, TabPanelBody } from 'components/tabs';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { ButtonsGroup, Button } from 'components/button';
import { Card, CardBody, CardFooter } from 'components/card';
import { QUERY_GET_EXERCISE_PROGRAM_TEMPLATE_JSON_LINK } from 'services/aws/exercises-query';
import { useQuery } from '@apollo/client';
import Loader from 'components/loader/Loader';
import { StoreContext } from 'index';
import Icon from 'components/icon/Icon';
import ReadOnlyInput from 'components/input/ReadOnlyInput';
import copy from 'copy-to-clipboard';
import { useNotificationQueue } from 'components/notification';
import LinkAsButton from 'components/link/Link';

const ShowEmbedUrlModal = ({ isOpen, onClose, templateId }) => {
  const {
    authStore: { entity }
  } = useContext(StoreContext);
  const intl = useIntl();
  const [value, setValue] = useState('');
  const tabIndex = 0;
  const [tabsIndex, setTabsIndex] = useState(tabIndex);

  const xApiKeyRef = useRef();
  const requestUrlRef = useRef();
  const exampleCurlRequestRef = useRef();
  const linkToTrainingRef = useRef();
  const notification = useNotificationQueue();

  const { loading, data } = useQuery(
    QUERY_GET_EXERCISE_PROGRAM_TEMPLATE_JSON_LINK,
    {
      variables: { templateId }
    }
  ); //

  useEffect(() => {
    if (data?.getExerciseProgramTemplateJsonLink) {
      const result = data.getExerciseProgramTemplateJsonLink;
      if (result) {
        setValue(result);
      }
    }
  }, [data]);

  const onCloseHandler = () => {
    if (notification.entries.length > 0) {
      notification.removeAll();
    }
    if (onClose) onClose();
  };

  const onCopyToClipboard = reference => {
    copy(reference?.current.innerText);

    const position = {
      y: reference?.current.parentElement.getBoundingClientRect().bottom + 4,
      x: reference?.current.parentElement.getBoundingClientRect().right
    };

    if (notification.entries.length > 0) {
      notification.removeAll();
    }

    notification.add(`copying-ref-copied-${position.y}`, {
      message:
        reference === linkToTrainingRef || reference === requestUrlRef
          ? intl.formatMessage(messages.notificationUrlCopied)
          : intl.formatMessage(messages.notificationContentCopied),
      position: position,
      closeable: true
    });
  };

  if (loading) return <Loader />;

  return (
    <Modal
      card
      isOpen={isOpen}
      onClose={onCloseHandler}
      extraClassNames={'c-card-modal__content-body-scroll'}
    >
      <CardHeader modal>
        <CardHeaderTitle>
          {intl.formatMessage(messages.modalTrainingEmbedUrlTitle)}
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          {intl.formatMessage(messages.modalTrainingEmbedSubtitle)}
        </CardHeaderSubtitle>
      </CardHeader>

      <CardBody modal bodyWithTabs>
        <Tabs
          onModal
          selectedIndex={tabsIndex}
          onSelect={index => {
            if (notification.entries.length > 0) {
              notification.removeAll();
            }
            return setTabsIndex(index);
          }}
        >
          <TabList>
            <Tab
              tabIndex="0"
              key="training-demo_tab"
              data-qa="training-demo_tab"
            >
              <FormattedMessage {...messages.tabTrainingPublish} />
            </Tab>
            <Tab tabIndex="0" key="training_api_tab" data-qa="training_api_tab">
              <FormattedMessage {...messages.tabTrainingAPI} />
            </Tab>
          </TabList>
          <TabPanel key="training-demo_tab">
            <TabPanelBody withContentAndCards>
              <CardHeader secondary extraClassNames="u-margin-bottom-small">
                <CardHeaderTitle>
                  <FormattedMessage {...messages.tabTrainingPublishTitle} />
                </CardHeaderTitle>
              </CardHeader>
              <CardBody secondary extraClassNames="u-margin-bottom-small">
                <CardHeaderSubtitle>
                  <FormattedMessage {...messages.tabTrainingPublishLeadCopy} />
                </CardHeaderSubtitle>
              </CardBody>
              <Card secondary>
                <CardHeader secondary extraClassNames="u-margin-bottom-small">
                  <CardHeaderTitle label>
                    <FormattedMessage
                      {...messages.tabTrainingPublishDelenTitle}
                    />
                  </CardHeaderTitle>
                </CardHeader>
                <CardBody secondary>
                  <p>
                    <FormattedMessage
                      {...messages.tabTrainingPublishDelenCopy}
                    />
                  </p>
                  <ReadOnlyInput
                    value={`https://exercises.hylyght.com/?apikey=${entity.apiKeys[0]}&trainingid=${templateId}`}
                    ref={linkToTrainingRef}
                  >
                    <Button
                      tiny
                      onClick={() => onCopyToClipboard(linkToTrainingRef)}
                    >
                      <Icon id="copy" strokeColor={'color-neutral-dark'}></Icon>
                    </Button>
                  </ReadOnlyInput>
                  <p className="u-margin-bottom-none">
                    <FormattedMessage
                      {...messages.tabTrainingPublishDelenVisitCopy}
                      values={{
                        a: chunks => (
                          <LinkAsButton
                            inline
                            external
                            to={{
                              pathname: `https://exercises.hylyght.com/?apikey=${entity.apiKeys[0]}&trainingid=${templateId}`
                            }}
                          >
                            {chunks}
                          </LinkAsButton>
                        )
                      }}
                    />
                  </p>
                </CardBody>
              </Card>
              <Card secondary>
                <CardHeader secondary extraClassNames="u-margin-bottom-small">
                  <CardHeaderTitle label>
                    <FormattedMessage
                      {...messages.tabTrainingPublishEmbedTitle}
                    />
                  </CardHeaderTitle>
                </CardHeader>
                <CardBody secondary>
                  <p className="u-margin-bottom-none">
                    <FormattedMessage
                      {...messages.tabTrainingPublishEmbedCopy}
                      values={{
                        a: chunks => (
                          <LinkAsButton
                            inline
                            external
                            to={{
                              pathname: `https://exercises.hylyght.com/demo.html?apikey=${entity.apiKeys[0]}&trainingid=${templateId}`
                            }}
                          >
                            {chunks}
                          </LinkAsButton>
                        )
                      }}
                    />
                  </p>
                </CardBody>
              </Card>
            </TabPanelBody>
          </TabPanel>
          <TabPanel key="training_api_tab">
            <TabPanelBody withContentAndCards>
              <CardBody secondary>
                <ReadOnlyInput
                  label={intl.formatMessage(
                    messages.modalTrainingEmbedApiKeyLabel
                  )}
                  value={`x-api-key: ${entity.apiKeys[0]}`}
                  ref={xApiKeyRef}
                >
                  <Button tiny onClick={() => onCopyToClipboard(xApiKeyRef)}>
                    <Icon id="copy" strokeColor={'color-neutral-dark'}></Icon>
                  </Button>
                </ReadOnlyInput>

                <ReadOnlyInput
                  label={intl.formatMessage(
                    messages.modalTrainingEmbedURLLabel
                  )}
                  value={value}
                  ref={requestUrlRef}
                >
                  <Button tiny onClick={() => onCopyToClipboard(requestUrlRef)}>
                    <Icon id="copy" strokeColor={'color-neutral-dark'}></Icon>
                  </Button>
                </ReadOnlyInput>

                <ReadOnlyInput
                  label={intl.formatMessage(
                    messages.modalTrainingEmbedExampleLabel
                  )}
                  value={`curl --location '${value}' --header 'x-api-key: ${entity.apiKeys[0]}'`}
                  ref={exampleCurlRequestRef}
                >
                  <Button
                    tiny
                    onClick={() => onCopyToClipboard(exampleCurlRequestRef)}
                  >
                    <Icon id="copy" strokeColor={'color-neutral-dark'}></Icon>
                  </Button>
                </ReadOnlyInput>
              </CardBody>
            </TabPanelBody>
          </TabPanel>
        </Tabs>
      </CardBody>
      <CardFooter modal>
        <ButtonsGroup>
          <Button primary onClick={onCloseHandler}>
            <FormattedMessage {...messages.cardButtonClose} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

export default ShowEmbedUrlModal;
