import React, { useContext } from 'react';
import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import { pollIsValidUrl } from 'utils/url';
import { openWindowWithLoader } from 'utils/browser';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';
import { downloadSkReportSession } from 'services/aws/session-query';
import Button from 'components/button/Button';
import Icon from 'components/icon/Icon';
import { useTestSetStore } from 'containers/pages/sessions/store/TestSetsStore';

const DownloadSKReportButton = ({ session, testData = null }) => {
  const { uiState } = useContext(StoreContext);
  const intl = useIntl();
  const hasTestSet = useTestSetStore(state => state.hasTestSet);

  const downloadSkSession = async (session, testDataId, bnw) => {
    const filenameQuery = await downloadSkReportSession(
      session.id,
      testDataId,
      bnw
    );

    if (filenameQuery) {
      return filenameQuery.data.generateSkReport;
    }
  };

  const downloadReport = async newWindow => {
    const openWindow = newWindow;

    try {
      uiState.increasePendingRequest();
      const url = await downloadSkSession(session, testData.id, false);
      if (url) {
        const response = await pollIsValidUrl(url, 25, 1000);
        if (response) {
          openWindow.location = url;
          openWindow.focus();
        }
      }
    } catch (error) {
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(messages.titleSessionHanReportDownloadFailed),
        message: messages.messageSessionHanReportDownloadFailed,
        dismissButton: false,
        type: MODAL_TYPES.WARNING
      });
    }

    uiState.decreasePendingRequest();
  };

  if (testData.finishedPercentage < 100) return null;

  console.log('DownloadSKReportButton');
  console.log('! is Ilike ||', session.isILike);
  console.log('! is IDo ||', session.isIDo);
  console.log('! is HanSisp ||', session.isHanSisp);
  console.log('! is IAm ||', session.isIAm);
  console.log('! is Djas ||', session.isDjas);
  console.log('! is HanIDo ||', session.isHanIDo);
  console.log('! is SkIDo', session.isSkIDo);
  console.log('! hasTestSet', hasTestSet(session.testSetIds[0]));
  console.log('isRecurring', session.isRecurring);

  if (
    !(
      session.isILike ||
      session.isIDo ||
      session.isHanSisp ||
      session.isIAm ||
      session.isDjas ||
      session.isHanIDo ||
      session.isSkIDo
    )
  )
    return <></>;

  if (!hasTestSet(session.testSetIds[0])) return <></>;
  if (session.isRecurring) return <></>;

  console.log('Show DownloadSKReportButton');

  return (
    <Button
      menu
      onClick={() => {
        const newWindow = openWindowWithLoader();
        downloadReport(newWindow);
      }}
      dataQa={`download-sk-report`}
    >
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemSessionDownloadHanReport} />
    </Button>
  );
};

export default DownloadSKReportButton;
