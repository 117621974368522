import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import { downloadSkReports } from 'services/aws/session-query';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';

const DownloadSKSessionsReportButton = ({ session }) => {
  const intl = useIntl();
  const {
    uiState,
    authStore: { user }
  } = useContext(StoreContext);

  const downloadSkSessionReports = async sessionId => {
    try {
      const query = await downloadSkReports(sessionId, true);
      if (query) {
        return query.data.downloadSkReports;
      }
    } catch (error) {
      if (error.message === 'no_reports_available') {
        // @ts-ignore
        uiState.showModal({
          title: intl.formatMessage(
            messages.titleSessionSKReportDownloadFailed
          ),
          message: messages.messageSessionAllReportsDownloadFailedNotAvailable,
          dismissButton: false,
          type: MODAL_TYPES.ALERT
        });
      }
      return false;
    }
  };

  const downloadAllReports = async () => {
    let response;
    console.log('!session.isTestSetV2', session.isTestSetV2);
    response = await downloadSkSessionReports(session.id);

    if (response) {
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(messages.titleSessionAllReportsMailSuccess),
        message: messages.messageSessionAllReportsMailSuccess,
        dismissButton: false,
        type: MODAL_TYPES.ALERT
      });
    }
  };

  console.log('DownloadSKSessionsReportButton');
  console.log('is han', user.isHanOrg);
  console.log('is ido', session.isIDo);
  console.log('!is isBenchmarked', session.isBenchmarked);
  console.log('is isRecurring', session.isRecurring);

  if (session.isTestSetV2 && !(session.isILike || session.isIDo)) return <></>;

  if (user.isHanOrg) return <></>;

  if (!session.isBenchmarked) return <></>;

  if (session.isRecurring) return <></>;

  if (session.isIDo) return <></>;

  console.log('Show DownloadSKSessionsReportButton');

  return (
    <Button menu onClick={downloadAllReports}>
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemSessionDownloadAllReports} />
    </Button>
  );
};

export default DownloadSKSessionsReportButton;
