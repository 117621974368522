// root auth
export const ROUTE_LOGIN = '/login';
export const ROUTE_SIGNUP = '/signup';
export const ROUTE_TOTP = '/totp';
export const ROUTE_FORGOT = '/forgot';
export const ROUTE_FORGOT_SUCCESS = '/forgot-success';
export const ROUTE_NO_ACCESS = '/no-access';
export const ROUTE_MAGIC_LOGIN = '/magic';

export const ROUTE_NO_ROLES = '/noroles';

export const ROUTE_LOADING = '/loading/:type?';

export const ROUTE_HOME = '/';

export const ROUTE_SUPPORT = 'https://hylyght.com/en/faq#using-the-platform';
export const ROUTE_HAN_SUPPORT =
  'https://beterlerenbewegen.han.nl/bloc-test-digitaal-platform';

// Packages
export const ROUTE_PACKAGES = '/packages';
export const ROUTE_PACKAGES_PACKAGE = `/packages/:packageType`;

// Settings
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_SETTINGS_SUB = '/settings/:setting';
export const ROUTE_SETTINGS_USERS = '/settings/users';
export const ROUTE_SETTINGS_USERS_ADD = '/settings/users/add';
export const ROUTE_SETTINGS_USERS_USER = '/settings/users/:userId';
export const ROUTE_SETTINGS_USERS_USER_EDIT = '/settings/users/:userId/edit';
export const ROUTE_SETTINGS_USERS_USER_ROLES = '/settings/users/:userId/roles';

export const ROUTE_SETTINGS_ORGANISATIONS = '/settings/organisations';
export const ROUTE_SETTINGS_ORGANISATIONS_ADD = '/settings/organisations/add';
export const ROUTE_SETTINGS_ORGANISATIONS_ORG =
  '/settings/organisations/:organisationId';
export const ROUTE_SETTINGS_ORGANISATIONS_ORG_EDIT =
  '/settings/organisations/:organisationId/edit';

export const ROUTE_SETTINGS_BENCHMARKS = '/settings/benchmarks';
// export const ROUTE_SETTINGS_BENCHMARKS_ADD = '/settings/benchmarks/add';
export const ROUTE_SETTINGS_BENCHMARKS_BENCHMARK =
  '/settings/benchmarks/:benchmarkId';
export const ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST =
  '/settings/benchmarks/:benchmarkId/test/:benchmarkTestId';
export const ROUTE_SETTINGS_BENCHMARKS_BENCHMARK_TEST_EDIT =
  '/settings/benchmarks/:benchmarkId/test/:benchmarkTestId/edit';

export const ROUTE_SETTINGS_TESTSETS = '/settings/testsets/:type?';
export const ROUTE_SETTINGS_TESTSETS_TESTSET =
  '/settings/testsets/:type/:testSetId';

export const ROUTE_SETTINGS_GLOBAL = '/settings/global';
export const ROUTE_SETTINGS_GLOBAL_EDIT = '/settings/global/edit';

export const ROUTE_SETTINGS_USAGE = '/settings/usage';
export const ROUTE_SETTINGS_EXPORT_DATA = '/settings/export';
export const ROUTE_SETTINGS_DEMODATA = '/settings/demodata';

export const ROUTE_SETTINGS_PROFILE = '/settings/profile';
export const ROUTE_SETTINGS_PROFILE_EDIT = '/settings/profile/edit';
export const ROUTE_SETTINGS_PROFILE_MFA = '/settings/mfa';

// Sporters
export const ROUTE_SPORTERS = '/athletes';
export const ROUTE_SPORTERS_ENTITY = '/athletes/:entityId/:groupId?';
export const ROUTE_SPORTERS_ENTITY_POOL = '/athletes/:entityId/all';
export const ROUTE_SPORTERS_ENTITY_SPORTER =
  '/athletes/:entityId/:groupId/:sporterId([a-z0-9-]{36})';
export const ROUTE_SPORTERS_ENTITY_SPORTER_EDIT =
  '/athletes/:entityId/:groupId/:sporterId([a-z0-9-]{36})/edit';
export const ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION =
  '/athletes/:entityId/:groupId/:sporterId/sessions/:sessionId([a-z0-9-]{36})';
export const ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT =
  '/athletes/:entityId/:groupId/:sporterId/sessions/:sessionId([a-z0-9-]{36})/report';
export const ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_REPORT_TESTDATA =
  '/athletes/:entityId/:groupId/:sporterId/sessions/:sessionId([a-z0-9-]{36})/report/:testDataId([a-z0-9-]{36})';
export const ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_EDIT =
  '/athletes/:entityId/:groupId/:sporterId/sessions/:sessionId([a-z0-9-]{36})/tests/edit';
export const ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS =
  '/athletes/:entityId/:groupId/:sporterId/sessions/:sessionId([a-z0-9-]{36})/tests';
export const ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA =
  '/athletes/:entityId/:groupId/:sporterId/sessions/:sessionId([a-z0-9-]{36})/tests/:testDataId([a-z0-9-]{36})';
export const ROUTE_SPORTERS_ENTITY_SPORTER_SESSIONS_SESSION_TESTS_TESTDATA_EDIT =
  '/athletes/:entityId/:groupId/:sporterId/sessions/:sessionId([a-z0-9-]{36})/tests/:testDataId([a-z0-9-]{36})/edit';
export const ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL =
  '/athletes/:entityId/:groupId/:sporterId/global';
export const ROUTE_SPORTERS_ENTITY_SPORTER_TESTS =
  '/athletes/:entityId/:groupId/:sporterId/tests';
export const ROUTE_SPORTERS_ENTITY_SPORTER_GLOBAL_EDIT =
  '/athletes/:entityId/:groupId/:sporterId/global/edit';

// Sessions
export const ROUTE_SESSIONS = '/sessions';
export const ROUTE_SESSIONS_ENTITY = '/sessions/:entityId/:sessionsTab?';
export const ROUTE_SESSIONS_ENTITY_SESSION =
  '/sessions/:entityId/:sessionsTab/:sessionId';
export const ROUTE_SESSIONS_ENTITY_SESSION_EDIT =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/edit';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTERS =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/athletes';
export const ROUTE_SESSIONS_ENTITY_SESSION_ADD_SPORTER =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/athletes/add';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/athletes/:sporterId([a-z0-9-]{36})';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/athletes/:sporterId([a-z0-9-]{36})/global';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/athletes/:sporterId([a-z0-9-]{36})/global/edit';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/athletes/:sporterId([a-z0-9-]{36})/report';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/athletes/:sporterId([a-z0-9-]{36})/report/:testDataId([a-z0-9-]{36})';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_EDIT =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/athletes/:sporterId([a-z0-9-]{36})/report/edit';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/athletes/:sporterId([a-z0-9-]{36})/tests';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/athletes/:sporterId([a-z0-9-]{36})/tests/:testDataId([a-z0-9-]{36})';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/athletes/:sporterId([a-z0-9-]{36})/tests/:testDataId([a-z0-9-]{36})/edit';
export const ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE =
  '/sessions/:entityId/:sessionsTab/:sessionId([a-z0-9-]{36})/athletes/:sporterId([a-z0-9-]{36})/advice/:programId';
export const ROUTE_SESSIONS_ENTITY_TEMPLATES = '/sessions/:entityId/templates';
export const ROUTE_SESSIONS_ENTITY_TEMPLATES_TESTSET_EDIT =
  '/sessions/:entityId/:sessionsTab/template/:testSetId/edit';

export const ROUTE_SESSIONS_ENTITY_SESSIONS = '/sessions/:entityId/sessions';
export const ROUTE_SESSIONS_ENTITY_RECURRING = '/sessions/:entityId/recurring';

// Scanners
export const ROUTE_SCANNERS = '/scanners';
export const ROUTE_SCANNERS_ENTITY = '/scanners/:entityId';
export const ROUTE_SCANNERS_ENTITY_ADD = '/scanners/:entityId/add';
export const ROUTE_SCANNERS_ENTITY_SCANNER = '/scanners/:entityId/:scannerId';
export const ROUTE_SCANNERS_ENTITY_SCANNER_EDIT =
  '/scanners/:entityId/:scannerId/edit';
export const ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS =
  '/scanners/:entityId/:scannerId/calibrations';

export const ROUTE_SCANNERS_ENTITY_SCANNER_DIAGRAM =
  '/scanners/:entityId/:scannerId/diagram';

export const ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_ADD =
  '/scanners/:entityId/:scannerId/calibrations/add';

export const ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION =
  '/scanners/:entityId/:scannerId/calibrations/:calibrationId';
export const ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_EDIT =
  '/scanners/:entityId/:scannerId/calibrations/:calibrationId/edit';
export const ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT =
  '/scanners/:entityId/:scannerId/calibrations/:calibrationId/result';
export const ROUTE_SCANNERS_ENTITY_SCANNER_CALIBRATIONS_CALIBRATION_RESULT_EDIT =
  '/scanners/:entityId/:scannerId/calibrations/:calibrationId/result/edit';

export const ROUTE_TESTS = '/tests';
export const ROUTE_TESTS_PACKAGE_SESSION =
  '/tests/:packageId/:entityId/:sessionId';
export const ROUTE_SURVEY_PACKAGE_SESSION_SPORTER =
  '/tests/:packageId/:entityId/:sessionId([a-z0-9-]{36})/:sporterId';
export const ROUTE_SURVEY_PACKAGE_SESSION_LIST =
  '/tests/sap/:entityId/:sessionId';

// Benchmarks
export const ROUTE_BENCHMARKS = '/benchmarks';
export const ROUTE_BENCHMARKS_ENTITY = '/benchmarks/:entityId/:popup';
export const ROUTE_BENCHMARKS_ENTITY_POPUP = '/benchmarks/:entityId/popup';
export const ROUTE_BENCHMARKS_ENTITY_SPORTER =
  '/benchmarks/:entityId/:popup/group/:groupId/sporter/:sporterId';
export const ROUTE_BENCHMARKS_ENTITY_GROUP =
  '/benchmarks/:entityId/:popup/group/:groupId/testset/:testSetId/benchmark/:benchmarkId';

export const ROUTE_BENCHMARKS_ENTITY_SESSION =
  '/benchmarks/:entityId/:popup/session/:sessionId';

// Results
export const ROUTE_RESULTS = '/results';
export const ROUTE_RESULTS_ENTITY = '/results/:entityId/:popup';
export const ROUTE_RESULTS_ENTITY_POPUP = '/results/:entityId/popup';
export const ROUTE_RESULTS_ENTITY_SPORTER =
  '/results/:entityId/:popup/group/:groupId/sporter/:sporterId';
export const ROUTE_RESULTS_ENTITY_GROUP =
  '/results/:entityId/:popup/group/:groupId/benchmark/:benchmarkId';

export const ROUTE_RESULTS_ENTITY_SESSION =
  '/results/:entityId/:popup/session/:sessionId';

// Growth
export const ROUTE_GROWTH_PREDICTION = '/growthprediction';
export const ROUTE_GROWTH_PREDICTION_ENTITY =
  '/growthprediction/:entityId/:popup';
export const ROUTE_GROWTH_PREDICTION_ENTITY_POPUP =
  '/growthprediction/:entityId/popup';
export const ROUTE_GROWTH_PREDICTION_ENTITY_SPORTER =
  '/growthprediction/:entityId/:popup/group/:groupId/sporter/:sporterId';
export const ROUTE_GROWTH_PREDICTION_ENTITY_GROUP =
  '/growthprediction/:entityId/:popup/group/:groupId/benchmark/:benchmarkId'; //testset/:testSetId/
export const ROUTE_GROWTH_PREDICTION_ENTITY_SESSION =
  '/growthprediction/:entityId/:popup/session/:sessionId';

// Growth Tracker
export const ROUTE_GROWTH = '/growthtracker';
export const ROUTE_GROWTH_ENTITY = '/growthtracker/:entityId/:popup';
export const ROUTE_GROWTH_ENTITY_POPUP = '/growthtracker/:entityId/popup';
export const ROUTE_GROWTH_ENTITY_SPORTER =
  '/growthtracker/:entityId/:popup/group/:groupId/sporter/:sporterId';
export const ROUTE_GROWTH_ENTITY_GROUP =
  '/growthtracker/:entityId/:popup/group/:groupId/benchmark/:benchmarkId';

export const ROUTE_GROWTH_ENTITY_SESSION =
  '/growthtracker/:entityId/:popup/session/:sessionId';

// Exercises
export const ROUTE_EXERCISES = '/exercises';

// Exercises
export const ROUTE_EXERCISES_INDEX = '/exercises/index';
export const ROUTE_EXERCISES_ENTITY = '/exercises/:entityId';
export const ROUTE_EXERCISES_ENTITY_ALL_EXERCISES = '/exercises/:entityId/all';
export const ROUTE_EXERCISES_ENTITY_MY_EXERCISES = '/exercises/:entityId/my';
export const ROUTE_EXERCISES_ENTITY_EXERCISES = '/exercises/:entityId/:tab';
export const ROUTE_EXERCISES_ENTITY_EXERCISE = `/exercises/:entityId/:tab/:exerciseId`;
export const ROUTE_EXERCISES_ENTITY_EXERCISE_EDIT = `/exercises/:entityId/:tab/:exerciseId/edit`;
export const ROUTE_EXERCISES_ENTITY_EXERCISE_LANGUAGE = `/exercises/:entityId/:exerciseId/language/:lang?`;

// Programs
export const ROUTE_EXERCISES_PROGRAMS = '/programs';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY = '/programs/:entityId';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM =
  '/programs/:entityId/:programId';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS =
  '/programs/:entityId/:programId/persons';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_FEEDBACK =
  '/programs/:entityId/:programId/persons/:personId/feedback';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL =
  '/programs/:entityId/:programId/persons/:personId/global';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_PERSONS_PERSON_GLOBAL_EDIT =
  '/programs/:entityId/:programId/persons/:personId/global/edit';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS =
  '/programs/:entityId/:programId/details';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_DETAILS_EDIT =
  '/programs/:entityId/:programId/details/edit';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT =
  '/programs/:entityId/:programId/edit';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES_PERSON =
  '/programs/:entityId/:programId/athletes/:personId';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_ATHLETES =
  '/programs/:entityId/:programId/athletes';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EDIT_EXERCISES =
  '/programs/:entityId/:programId/exercises';
export const ROUTE_EXERCISES_PROGRAMS_ENTITY_PROGRAM_EXERCISES_EXERCISE =
  '/programs/:entityId/:programId/exercises/:exerciseId';

// Templates
export const ROUTE_EXERCISES_TEMPLATES = '/templates';
export const ROUTE_EXERCISES_TEMPLATES_ENTITY = '/templates/:entityId';
export const ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE =
  '/templates/:entityId/:templateId';
export const ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_DETAILS =
  '/templates/:entityId/:templateId/details';
export const ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_DETAILS_EDIT =
  '/templates/:entityId/:templateId/details/edit';
export const ROUTE_EXERCISES_TEMPLATES_ENTITY_TEMPLATE_EXERCISES_EXERCISE =
  '/templates/:entityId/:templateId/exercises/:exerciseId';

// Collections
export const ROUTE_EXERCISES_COLLECTIONS = '/collections';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY = '/collections/:entityId';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTIONS =
  '/collections/:entityId/:tab';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_MY_COLLECTIONS =
  '/collections/:entityId/my';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_OTHER_COLLECTIONS =
  '/collections/:entityId/other';

export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION =
  '/collections/:entityId/:tab/:templateId';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS =
  '/collections/:entityId/:tab/:templateId/details';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_DETAILS_EDIT =
  '/collections/:entityId/:tab/:templateId/details/edit';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES =
  '/collections/:entityId/:tab/:templateId/exercises';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE =
  '/collections/:entityId/:tab/:templateId/exercises/:exerciseId';
export const ROUTE_EXERCISES_COLLECTIONS_ENTITY_COLLECTION_EXERCISES_EXERCISE_EDIT =
  '/collections/:entityId/:tab/:templateId/exercises/:exerciseId/edit';

// Trainings
export const ROUTE_EXERCISES_TRAININGS = '/trainings';
export const ROUTE_EXERCISES_TRAININGS_ENTITY = '/trainings/:entityId';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS_EDIT =
  '/trainings/:entityId/:templateId/details/edit';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_DETAILS =
  '/trainings/:entityId/:templateId/details';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING =
  '/trainings/:entityId/:templateId';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_EDIT =
  '/trainings/:entityId/:templateId/exercises/:exerciseId/edit';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_DETAILS =
  '/trainings/:entityId/:templateId/exercises/:exerciseId/details';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE_DETAILS_EDIT =
  '/trainings/:entityId/:templateId/exercises/:exerciseId/details/edit';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES =
  '/trainings/:entityId/:templateId/exercises';
export const ROUTE_EXERCISES_TRAININGS_ENTITY_TRAINING_EXERCISES_EXERCISE =
  '/trainings/:entityId/:templateId/exercises/:exerciseId';

// Rehab
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId?';

// Injury
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_DETAIL_EDIT =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId/edit';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_REPORTS =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId/reports';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId/screenings';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_ENTITY =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId/global';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_DETAIL_EDIT =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId/global/edit';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_RETEST =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId([a-z0-9-]{36})/retest';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId([a-z0-9-]{36})/tests';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId([a-z0-9-]{36})/tests/:testDataId([a-z0-9-]{36})';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_TESTS_TESTDATA_EDIT =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId([a-z0-9-]{36})/tests/:testDataId([a-z0-9-]{36})/edit';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT_TESTDATA =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId([a-z0-9-]{36})/report/:testDataId([a-z0-9-]{36})';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_INJURY_SCREENINGS_REPORT =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/injury/:injuryId/screenings/:sessionId([a-z0-9-]{36})/report';

// Illness

export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/illness/:illnessId';

export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ILLNESS_DETAIL_EDIT =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/illness/:illnessId/edit';

// Issues

export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/issue/:issueId';
export const ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT =
  '/athletes/:entityId/:groupId/:sporterId/medical/:medicalId/issue/:issueId/edit';

// Prevention
export const ROUTE_PREVENTION = '/prevention';
export const ROUTE_PREVENTION_ENTITY_ADD_PREVENTION_SPORTERS =
  '/prevention/:entityId/add/athletes';
export const ROUTE_PREVENTION_ENTITY_EDIT_PREVENTION =
  '/prevention/:entityId/:sessionId([a-z0-9-]{36})/edit';

// User Programs
export const ROUTE_USER_PROGRAMS = '/programs';
export const ROUTE_USER_ENTITY_PROGRAMS = '/programs/:entityId';
export const ROUTE_USER_ENTITY_PROGRAMS_PROGRAM =
  '/programs/:entityId/:programId';
export const ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_FEEDBACK =
  '/programs/:entityId/:programId/feedback';
export const ROUTE_USER_ENTITY_PROGRAMS_PROGRAM_EXERCISE =
  '/programs/:entityId/:programId/exercise/:exerciseId';
export const ROUTE_USER_ENTITY = '/user/:entityId';

// User Sessions
export const ROUTE_USER_SESSIONS_ENTITY = '/sessions/:entityId';
export const ROUTE_USER_SESSIONS_ENTITY_MEDICAL = '/sessions/:entityId/medical';
export const ROUTE_USER_SESSIONS_ENTITY_SESSION =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})';
export const ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/report';
export const ROUTE_USER_SESSIONS_ENTITY_SESSION_REPORT_TESTDATA =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/report/:testDataId([a-z0-9-]{36})';
export const ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/tests';
export const ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/tests/:testDataId([a-z0-9-]{36})';
export const ROUTE_USER_SESSIONS_ENTITY_SESSION_TESTS_TESTDATA_EDIT =
  '/sessions/:entityId/:sessionId([a-z0-9-]{36})/tests/:testDataId([a-z0-9-]{36})/edit';

// User Exercises
export const ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS =
  '/athletes/:entityId/:groupId/:sporterId/programs';
export const ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM =
  '/athletes/:entityId/:groupId/:sporterId/programs/:programId';
export const ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_EXERCISE =
  '/athletes/:entityId/:groupId/:sporterId/programs/:programId/:exerciseId([a-z0-9-]{36})';
export const ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM_FEEDBACK =
  '/athletes/:entityId/:groupId/:sporterId/programs/:programId/feedback';

// User Trainings
export const ROUTE_SPORTERS_ENTITY_SPORTER_TRAININGS =
  '/athletes/:entityId/:groupId/:sporterId/trainings';

// User Groups
export const ROUTE_SPORTERS_ENTITY_SPORTER_GROUPS =
  '/athletes/:entityId/:groupId/:sporterId/groups';

// User docs
export const ROUTE_SPORTERS_ENTITY_SPORTER_DOCS =
  '/athletes/:entityId/:groupId/:sporterId/documents';
export const ROUTE_USER_ENTITY_DOCUMENTS = '/documents/:entityId';
export const ROUTE_USER_DOCUMENTS = '/documents';

// Vitale routes
export const ROUTE_LESSONS = '/lessons';
export const ROUTE_LESSONS_ENTITY = '/lessons/:entityId';
export const ROUTE_LESSONS_ENTITY_LESSON = '/lessons/:entityId/:lessonId/';
export const ROUTE_LESSONS_ENTITY_LESSON_EDIT =
  '/lessons/:entityId/:lessonId/edit';

export const ROUTE_LESSONS_ENTITY_LESSON_ANNOTATE =
  '/lessons/:entityId/:lessonId/annotate';
export const ROUTE_LESSONS_ENTITY_LESSON_VIDEOS =
  '/lessons/:entityId/:lessonId/videos';
export const ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO =
  '/lessons/:entityId/:lessonId/videos/:videoId';
export const ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_EDIT =
  '/lessons/:entityId/:lessonId/videos/:videoId/edit';
export const ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE = `/lessons/:entityId/:lessonId/videos/:videoId/annotate`;
export const ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE_ADD = `/lessons/:entityId/:lessonId/videos/:videoId/annotate/add`;
export const ROUTE_LESSONS_ENTITY_LESSON_VIDEOS_VIDEO_ANNOTATE_EDIT = `/lessons/:entityId/:lessonId/videos/:videoId/annotate/edit/:annotationId`;

export const ROUTE_VIDEOS = '/videos';
export const ROUTE_VIDEOS_INDEX = '/videos/index';
export const ROUTE_VIDEOS_ENTITY = '/videos/:entityId';
export const ROUTE_VIDEOS_ENTITY_VIDEO = `/videos/:entityId/:videoId`;
export const ROUTE_VIDEOS_ENTITY_VIDEO_ADD = `/videos/:entityId/add`;
export const ROUTE_VIDEOS_ENTITY_VIDEO_EDIT = `/videos/:entityId/:videoId/edit`;
export const ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE = `/videos/:entityId/:videoId/annotate`;
export const ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE_ADD = `/videos/:entityId/:videoId/annotate/add`;
export const ROUTE_VIDEOS_ENTITY_VIDEO_ANNOTATE_EDIT = `/videos/:entityId/:videoId/annotate/edit/:annotationId`;

export const ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS =
  '/athletes/:entityId/:groupId/:sporterId/videos';
export const ROUTE_SPORTERS_ENTITY_SPORTER_VIDEOS_VIDEO =
  '/athletes/:entityId/:groupId/:sporterId/videos/:videoId?';

// Test app
export const ROUTE_TEST = '/test';
export const ROUTE_TEST_ENTITY = '/test/:entityId';
export const ROUTE_TEST_ENTITY_UPLOAD = '/test/:entityId/upload';
export const ROUTE_TEST_ENTITY_EXERCISES = '/test/:entityId/exercises';
export const ROUTE_TEST_ENTITY_EXERCISES_EXERCISE_PYTHON =
  '/test/:entityId/exercises/:exerciseId/:type';
export const ROUTE_TEST_ENTITY_EXERCISES_EXERCISE =
  '/test/:entityId/exercises/:exerciseId/:type?';
export const ROUTE_TEST_ENTITY_ANALYSE = '/test/:entityId/analyse/:type';
export const ROUTE_TEST_ENTITY_ANALYSE_PYTHON = '/test/:entityId/analyse/:type';
