import React, { useContext } from 'react';
import { MUTATION_MAIL_ALL_SESSION_REPORT } from 'services/aws/session-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from 'containers/partials/buttons/ConfirmMutationButton';
import messages from 'messages';
import { MODAL_TYPES } from 'models/ModalData';
import { FormattedMessage, useIntl } from 'react-intl';
import { StoreContext } from 'index';
import { useNotificationQueue } from 'components/notification';

const MailAllReportsToSportersButton = ({
  entityId,
  session,
  options,
  children,
  ...props
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const notification = useNotificationQueue();
  const intl = useIntl();

  console.log('MailAllReportsToSportersButton');
  console.log('! user isHylyghtOrg &&', !user.isHylyghtOrg);
  console.log('(is han', user.isHanOrg);
  console.log('is ido', session.isIDo);
  console.log('is ilike', session.isILike);
  console.log('is isIAm', session.isIAm);
  console.log('is isDjas', session.isDjas);
  console.log('is isHan', session.isHan);
  console.log('user isHanOrg', user.isHanOrg);
  console.log('is isHanSisp', session.isHanSisp);
  console.log('is isKlusce )', session.isKlusce);
  console.log('is isRecurring', session.isRecurring);

  if (
    !user.isHylyghtOrg &&
    (session.isIDo ||
      session.isILike ||
      session.isIAm ||
      session.isDjas ||
      session.isHan ||
      user.isHanOrg ||
      session.isHanSisp ||
      session.isKlusce)
  )
    return <></>;
  if (session.isRecurring) return <></>;

  const { benchmark, ...restOptions } = options;
  const data = {
    sessionId: session.id,
    options: restOptions
  };
  if (!session.isRehab && !session.isPrevention) {
    data.benchmarkId = benchmark;
  }

  if (!session.isPrevention && !session.isRehab) return <></>;

  console.log('Show MailAllReportsToSportersButton');

  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_MAIL_ALL_SESSION_REPORT}
      confirmTitle={messages.modalConfirmMailAllReportsTitle}
      confirmMessage={messages.modalConfirmMailAllReportsMessage}
      confirmAcceptLabel={messages.modalConfirmMailReportAcceptButton}
      confirmType={MODAL_TYPES.WARNING}
      variables={data}
      update={(cache, { data: { mailSessionAllReportOfTestSession } }) => {
        if (mailSessionAllReportOfTestSession) {
          notification.add('mailSessionAllReportOfTestSession', {
            message: intl.formatMessage(
              messages.modalReportSendToSporterSuccessMessage
            )
          });
        }
      }}
    >
      <Icon id="send" />
      <FormattedMessage {...messages.menuItemSessionMailAllReportsToPersons} />
    </ConfirmMutationButton>
  );
};

export default MailAllReportsToSportersButton;
