import { TableCell, TableRow } from 'components/table';
import Icon from 'components/icon/Icon';
import slugify from 'slugify';
import { sanitize } from 'dompurify';

import messages from 'messages';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import Tooltip from 'components/tooltip/Tooltip';
import { getGrowthColor } from 'containers/pages/benchmarks/utils/benchmarkUtil';
import TooltipPopper from 'components/tooltip/TooltipPopper';
import Graphic from 'components/graphics/Graphic';

const GrowthTableRow = ({ user, cell, colsLength }) => {
  //   console.log('CELL', cell, colsLength);

  return (
    <TableRow>
      {user && (
        <TableCell
          mainCell
          id={user?.label ? slugify(user.label).toLowerCase() : ''}
        >
          <div className="o-flex">
            {user?.label ? user.label : ''}
            {user?.alertOldData && (
              <Tooltip
                id="warning-date-2"
                iconFillColor
                alert
                right
                smallIcon
                icon="alert-round"
                style={{
                  display: 'inline-flex',
                  zIndex: 1,
                  marginLeft: 'auto',
                  textTransform: 'none'
                }}
              >
                <FormattedMessage {...messages.growthPredictionTableWarning} />
              </Tooltip>
            )}
          </div>
        </TableCell>
      )}
      {Object.keys(cell).map((key, index) => {
        return (
          <TableCell
            //   sortedBy={index === 2} // TODO: @Maarten, `sortIndex` zowel in TableBody's TableCell als in TableHeader's TableCell
            key={index}
            style={{
              ...(colsLength > 4 ? { padding: '0 6px' } : {})
            }}
            outfaded={user?.alertOldData}
            data-value={key}
          >
            <ResultCell
              testItem={cell[key]}
              testItemName={key}
              userName={user.label}
            />
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default GrowthTableRow;

// TODO: work in progress
// still need to adapt the cases for inputs with colors, and icons
const ResultCell = ({ testItem, testItemName, userName }) => {
  const intl = useIntl();

  const testItemValue = testItem.value;

  if (testItemName === 'developmentZScore') {
    return testItemValue && Array.isArray(testItemValue) ? (
      <div
        className={classNames('r-table-cell__content')}
        style={{
          maxWidth: '75%',
          margin: 'auto',
          justifyContent: 'space-between'
        }}
      >
        {testItemValue.map((val, i) => {
          return (
            <span
              key={i}
              dangerouslySetInnerHTML={{
                __html: sanitize(val, {
                  ALLOWED_TAGS: ['span']
                })
              }}
            />
          );
        })}
      </div>
    ) : (
      <div className="r-table-cell__content">&mdash;</div>
    );
  } else if (testItemName === 'pah90Ci') {
    return (
      <div className="r-table-cell__content">
        {testItemValue && typeof testItemValue === 'string' ? (
          <>
            {testItemValue}
            {testItem.unit && (
              <span className="r-table-cell__unit">{testItem.unit}</span>
            )}
          </>
        ) : (
          <>&mdash;</>
        )}
      </div>
    );
  } else if (testItemName === 'growthPhase') {
    return testItemValue && typeof testItemValue === 'string' ? (
      <div
        className={`r-table-cell__content r-table-cell__content-colors u-${testItem.alertLevel >= 0 && getGrowthColor(testItem.alertLevel)}`}
      >
        <span className="u-text-uppercase">{testItemValue}</span>
        {testItem.alertLevel >= 0 && (
          <Icon
            id={testItem.iconWarning ? 'warning-sign' : 'check-circle'}
            extraClassNames={'c-icon--on-table-cell'}
          />
        )}
      </div>
    ) : (
      <div className="r-table-cell__content">&mdash;</div>
    );
  } else if (testItemName === 'preciseAge') {
    return (
      <div className="r-table-cell__content">
        {testItemValue && typeof testItemValue === 'number' ? (
          <FormattedMessage
            {...messages.growthTrackerValueUnit}
            values={{
              value: testItemValue,
              unit: testItem.unit,
              span: chunks => (
                <span className="r-table-cell__unit">{chunks}</span>
              )
            }}
          />
        ) : (
          <>&mdash;</>
        )}
      </div>
    );
  } else if (testItemName === 'predictedAdultHeight') {
    // const testItemValue = 81.21;
    return (
      <div
        className={classNames(
          'r-table-cell__content',
          `u-${getGrowthColor(testItem.alertLevel)}`
        )}
      >
        {testItemValue ? (
          <TooltipPopper
            inheritedStyles
            id={`table-cell-${testItemName}-user-${slugify(userName)}`}
            labelObject={
              <>
                <span>
                  <FormattedMessage
                    {...messages.growthTrackerValueUnit}
                    values={{
                      value: testItemValue,
                      unit: testItem.unit,
                      span: chunks => (
                        <span className="r-table-cell__unit">{chunks}</span>
                      )
                    }}
                  />
                </span>
                {testItem.alertLevel >= 0 && (
                  <Icon
                    id={testItem.iconWarning ? 'warning-sign' : 'check-circle'}
                    extraClassNames={'c-icon--on-table-cell'}
                    inheritColor={
                      testItem.alertLevel >= 0 &&
                      getGrowthColor(testItem.alertLevel)
                    }
                  />
                )}
              </>
            }
            openOnClick
            onClick={() => true}
            graphic
          >
            <p className="u-margin-bottom-small">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              placerat ligula arcu, id tincidunt nulla dictum vel.
            </p>
            {testItemValue && (
              <Graphic
                pah
                pahRefactored
                value={parseFloat(testItemValue.toFixed(2))}
                position={
                  testItemValue > 99.4
                    ? 99.4
                    : testItemValue < 80.1
                      ? 80.1
                      : testItemValue
                }
                extraClassNames={[
                  testItemValue > 99
                    ? 'c-graphic--value-right'
                    : testItemValue < 81.19
                      ? 'c-graphic--value-left'
                      : null,
                  `u-${getGrowthColor(testItem.alertLevel)}`
                ]}
              />
            )}
            <p className="u-margin-top-small u-margin-bottom-none">
              Etiam imperdiet diam at porta cursus. Nulla rhoncus sit amet nisi
              in semper. Curabitur venenatis hendrerit nisl, in pulvinar massa
              blandit at.
            </p>
          </TooltipPopper>
        ) : (
          <>&mdash;</>
        )}
      </div>
    );
  } else {
    return (
      <div className="r-table-cell__content">
        {testItemValue && typeof testItemValue === 'number' ? (
          <>
            <FormattedMessage
              {...messages.growthTrackerValueUnit}
              values={{
                value: testItemValue,
                unit: testItem.unit,
                span: chunks => (
                  <span className="r-table-cell__unit">{chunks}</span>
                )
              }}
            />
            {testItem.alertLevel >= 0 && (
              <Icon
                id={testItem.iconWarning ? 'warning-sign' : 'check-circle'}
                extraClassNames={'c-icon--on-table-cell'}
                inheritColor={
                  testItem.alertLevel >= 0 &&
                  getGrowthColor(testItem.alertLevel)
                }
              />
            )}
          </>
        ) : (
          <>&mdash;</>
        )}
      </div>
    );
  }
};
