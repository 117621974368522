import React from 'react';
import classNames from 'classnames';

interface RefactoredTableHeaderCellProps {
  children?: React.ReactNode;
}

const RefactoredTableHeaderCell = ({
  children
}: RefactoredTableHeaderCellProps) => {
  return (
    <th className={classNames('r-table-cell', {})}>
      <div className={classNames('o-flex')}>{children}</div>
    </th>
  );
};

export default RefactoredTableHeaderCell;
