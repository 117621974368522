import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import { StoreContext } from 'index';
import Button from 'components/button/Button';
import Session from 'models/Session';

const CopySessionButton = ({ session, onCopySession }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);

  const onClickHandler = e => {
    e.preventDefault();
    const newSession = new Session({ ...session });
    newSession.id = null;
    newSession.startdate = new Date();

    onCopySession && onCopySession(newSession);
  };

  if (!user.isHylyghtOrg || session.isTalentV2) return <></>;

  return (
    <Button menu onClick={onClickHandler}>
      <Icon id="copy" />
      <FormattedMessage {...messages.menuItemCopySession} />
    </Button>
  );
};

export default CopySessionButton;
