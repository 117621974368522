import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Drawer from 'components/drawer/Drawer';
import {
  Navigation,
  NavigationItem,
  NavigationWrapper,
  NavigationItemsGroup
} from 'components/navigation';

import messages from 'messages';
import { DrawerHeader } from 'components/drawer';
import {
  ROUTE_SETTINGS,
  ROUTE_SPORTERS,
  ROUTE_HAN_SUPPORT,
  ROUTE_SUPPORT,
  ROUTE_GROWTH
} from 'routes/RouteList';
import { drawerGroups, PackageTypes } from 'constants.js';
import { StoreContext } from 'index';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';
import { useHistory, useLocation } from 'react-router-dom';
import { MODAL_TYPES } from 'models/ModalData';

const DrawerView = forwardRef((props, ref) => {
  const {
    authStore,
    authStore: { user },
    uiState
  } = useContext(StoreContext);
  const { push } = useHistory();
  const location = useLocation();
  const intl = useIntl();
  const ability = useAbility(AbilityContext);
  const [drawerType, setDrawerType] = useState([]);

  const logout = () => {
    props.handleCloseDrawer();
    authStore.doLogout();
  };

  const isOpenDrawer = props.isOpenDrawer;
  const closeDrawer = () => props.handleCloseDrawer();

  useEffect(() => {
    if (props.type) {
      setDrawerType(drawerGroups(props.type));
    }
  }, [user, props.type]);

  const navigateToHome = () => {
    push(uiState.homeRoute);
  };

  const openSupportModal = () => {
    uiState.showModal({
      title: intl.formatMessage(messages.modalSupportTitle),
      message: user.isHanOrg
        ? messages.modalSupportHanMessage
        : messages.modalSupportMessage,
      values: {
        email: user.isHanOrg ? 'seneca@han.nl' : 'support@hylyght.com',
        a: chunks => <a href={`mailto:${chunks}`}>{chunks}</a>,
        br: () => <br />
      },
      type: MODAL_TYPES.WARNING,
      okLabel: intl.formatMessage(
        user.isHanOrg
          ? messages.modalSupportHanOkButton
          : messages.modalSupportOkButton
      ),
      dismissLabel: intl.formatMessage(messages.modalSupportDismissButton),
      okHandler: async () => {
        window.open(
          user.isHanOrg ? ROUTE_HAN_SUPPORT : ROUTE_SUPPORT,
          '_blank'
        );
      }
    });
  };

  return (
    <Drawer open={isOpenDrawer} ref={ref}>
      <DrawerHeader
        title={user?.name}
        logo={user?.logo}
        to={uiState.homeRoute}
        onClick={() => {
          closeDrawer();
          navigateToHome();
        }}
      />
      <Navigation>
        <NavigationWrapper>
          {user &&
            drawerType.map((groupitem, i) => {
              const items = groupitem.items.filter(item =>
                ability.can('read', item.ability)
              );
              if (items.length) {
                return (
                  <NavigationItemsGroup key={i}>
                    {items.map(item => {
                      if (user.hasNoRoles() && user.hasGroupsToShow) {
                        if (item.route !== ROUTE_SPORTERS) {
                          return null;
                        }
                      }

                      return (
                        <NavigationItem
                          id={item.route.replace('/', '')}
                          key={item.route}
                          icon={item.icon}
                          iconSize={item.iconSize ? item.iconSize : false}
                          to={item.route}
                          active={[
                            item.route,
                            ...(item.subroutes ?? [])
                          ].includes(
                            location.pathname.substring(
                              0,
                              location.pathname.indexOf('/', 1)
                            )
                          )}
                          onClick={() => {
                            closeDrawer();
                          }}
                          label={intl.formatMessage(item.label)}
                          //   Adding a badge to show new element
                          isNew={item.route === ROUTE_GROWTH}
                        />
                      );
                    })}
                  </NavigationItemsGroup>
                );
              }
              return null;
            })}
          {user &&
            user.hasAdminRole() &&
            !user.isGSVOrg &&
            process.env.REACT_APP_INTERCOM_ENABLED === 'true' && (
              <NavigationItem
                id={'intercom-launcher'}
                icon="question"
                bottom
                label={intl.formatMessage(messages.drawerSupport)}
              >
                <FormattedMessage {...messages.drawerSupport} />
              </NavigationItem>
            )}
          {user &&
            user.hasAdminRole() &&
            !user.isGSVOrg &&
            (user.isHylyghtOrg || user.isHanOrg) &&
            process.env.REACT_APP_INTERCOM_ENABLED === 'false' && (
              <NavigationItem
                to={'#'}
                id={'faq-launcher'}
                icon="question"
                bottom
                onClick={openSupportModal}
                label={intl.formatMessage(messages.drawerSupport)}
              >
                <FormattedMessage {...messages.drawerSupport} />
              </NavigationItem>
            )}

          {user && ability.can('manage', 'Settings') && (
            <NavigationItem
              component={NavigationItem}
              to={ROUTE_SETTINGS}
              icon="settings"
              bottom
              label={intl.formatMessage(messages.drawerSettings)}
              active={location.pathname.startsWith(ROUTE_SETTINGS)}
            />
          )}
        </NavigationWrapper>

        <NavigationItem
          to={'#'}
          secondary
          signout
          icon="signout"
          onClick={logout}
          label={intl.formatMessage(messages.drawerLogout)}
        >
          <FormattedMessage {...messages.drawerLogout} />
        </NavigationItem>
      </Navigation>
    </Drawer>
  );
});
export default DrawerView;
