import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Modal from 'components/modal/Modal';
import messages from 'messages';
import {
  CardHeader,
  CardHeaderTitle,
  CardHeaderSubtitle
} from 'components/card/card-header';
import { ButtonsGroup, Button } from 'components/button';
import { CardBody, CardFooter } from 'components/card';
import ReportOptions from 'containers/partials/forms/ReportOptions';
import { MODAL_TYPES } from 'models/ModalData';
import {
  downloadAllSessionReports,
  downloadSkReports,
  downloadSkReportSession
} from 'services/aws/session-query';
import { StoreContext } from 'index';
import { pollIsValidUrl } from 'utils/url';
import { openWindowWithLoader } from 'utils/browser';
import { useSessionContext } from 'contexts/SessionContext';
import Loader from 'components/loader/Loader';
import { uid } from 'react-uid';

export const downloadSessions = async (session, options) => {
  const { benchmark, ...restOptions } = options;
  const response = await downloadAllSessionReports(
    session.id,
    session.isTestSetV2 && (session.isPrevention || session.isRehab)
      ? null
      : benchmark,
    restOptions
  );
  return (
    !response.error && response.data && response.data.downloadSessionReports
  );
};

export const downloadSkSessionReports = async (sessionId, uiState, intl) => {
  try {
    const query = await downloadSkReports(sessionId, true);
    if (query) {
      return query.data.downloadSkReports;
    }
  } catch (error) {
    if (error.message === 'no_reports_available') {
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(messages.titleSessionSKReportDownloadFailed),
        message: messages.messageSessionAllReportsDownloadFailedNotAvailable,
        dismissButton: false,
        type: MODAL_TYPES.ALERT
      });
    }
    return false;
  }
};

export const generateSkSessionReports = async (
  sessionId,
  newWindow,
  uiState,
  intl
) => {
  const openWindow = newWindow;

  try {
    const filenameQuery = await downloadSkReportSession(sessionId);

    if (filenameQuery) {
      const url = filenameQuery.data.generateSkReport;
      const response = await pollIsValidUrl(url, 25, 1000);

      if (response) {
        openWindow.location = url;
        openWindow.focus();
      } else {
        // noinspection ExceptionCaughtLocallyJS
        throw Error('no pdf found');
      }
    }
  } catch (error) {
    // @ts-ignore
    uiState.showModal({
      title: intl.formatMessage(messages.titleSessionSKReportDownloadFailed),
      message: messages.messageSessionSKReportDownloadFailed,
      dismissButton: false,
      type: MODAL_TYPES.WARNING
    });
  }
};

export const downloadAllReports = async (
  session,
  newWindow,
  uiState,
  options,
  intl,
  setIsLoading
) => {
  setIsLoading && setIsLoading(true);

  let response;
  if (!session.isTestSetV2 && (session.isILike || session.isIDo)) {
    response = await downloadSkSessionReports(session.id, uiState, intl);
  }
  if (!session.isTestSetV2 && session.isIAm) {
    response = await generateSkSessionReports(
      session.id,
      newWindow,
      uiState,
      intl
    );
  }
  if (session.isTestSetV2) {
    response = await downloadSessions(session, options);
  }

  if (response) {
    // @ts-ignore
    setIsLoading && setIsLoading(false);

    return uiState.showModal({
      title: intl.formatMessage(messages.titleSessionAllReportsMailSuccess),
      message: messages.messageSessionAllReportsMailSuccess,
      dismissButton: false,
      type: MODAL_TYPES.ALERT
    });
  }

  setIsLoading && setIsLoading(false);
};

const DownloadReportOptionsModal = ({ onClose }) => {
  const intl = useIntl();
  const { uiState } = useContext(StoreContext);
  const { session, options } = useSessionContext();
  const [isLoading, setIsLoading] = useState(false);

  const onCloseHandler = () => {
    if (onClose) onClose();
  };

  return (
    <Modal card largeBody isOpen={true} onClose={onCloseHandler}>
      {isLoading && <Loader />}
      <CardHeader modal>
        <CardHeaderTitle>
          {intl.formatMessage(messages.modalDownloadReportOptionsTitle)}
        </CardHeaderTitle>
        <CardHeaderSubtitle>
          {intl.formatMessage(messages.modalDownloadReportOptionsSubtitle)}
        </CardHeaderSubtitle>
      </CardHeader>

      <CardBody modal style={{ overflowY: 'scroll' }}>
        <ReportOptions
          key={uid('modalReportOptions')}
          inlineSelect
          openAccordion
          onModal
        />
      </CardBody>
      <CardFooter modal style={{ zIndex: 9 }}>
        <ButtonsGroup style={{ width: '100%', justifyContent: 'center' }}>
          <Button secondary onClick={onCloseHandler}>
            <FormattedMessage {...messages.buttonCancel} />
          </Button>
          <Button
            primary
            onClick={async () => {
              const newWindow =
                !session.isTestSetV2 && session.isIAm
                  ? openWindowWithLoader()
                  : null;
              await downloadAllReports(
                session,
                newWindow,
                uiState,
                options,
                intl,
                setIsLoading
              );
            }}
          >
            <FormattedMessage {...messages.menuItemSessionDownloadAllReports} />
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

export default DownloadReportOptionsModal;
