import {
  TableHeaderCell,
  TableRow,
  RefactoredTableHeader
} from 'components/table';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import { SORT_TYPES } from 'utils/sort';
import FieldSelect from 'components/input/FieldSelect';
import messages from 'messages.js';
import { sanitize } from 'dompurify';

import { useIntl } from 'react-intl';
import { useStore } from 'stores/Store';
import { Fragment, useEffect, useState } from 'react';
import useUrlQuery from 'hooks/utils/useUrlQuery';
import { useGrowthPanelContext } from 'contexts/GrowthPanelContext';
import RefactoredTableHeaderCell from 'components/table/RefactoredTableHeaderCell';

const GrowthTableHeader = () => {
  let query = useUrlQuery();
  const intl = useIntl();
  const {
    cols,
    testItems,
    usersArray,
    onChangeAthleteCol,
    onSort,
    onSortAthletes,
    sortIndex,
    athletesSortDirection
  } = useGrowthPanelContext();

  const selectedHeaders = useStore(state => state.selectedHeaders);
  const { setSelectedHeaders, setSelectedHeader } = useStore(state => state);

  // TODO: When we are showing the 4 basic columns should activate this flag
  // otherwise, the size of the columns will be smaller
  const isBasic = cols.length === 4;

  useEffect(() => {
    const tests = query.get('tests');
    if (tests) {
      const testsArray = tests.split(',');
      const testIds = [];
      testsArray.forEach((test, index) => {
        testIds.push({ id: test, sortDirection: SORT_TYPES.DOWN });
      });
      setSelectedHeaders([...testIds]);
    }
  }, [query]);

  //   const onSelectTestHandler = (index, value) => {
  //     setSelectedHeader({ index, value });

  //     const testIds = selectedHeaders.map(item => item.id);
  //     const testsQuery = testIds.join(',');
  //     query.set('tests', testsQuery);
  //     window.history.replaceState(null, null, `?${query.toString()}`);
  //   };

  return (
    <RefactoredTableHeader sticky>
      <TableRow>
        <RefactoredTableHeaderCell>
          {/* <FieldSelect
            name={`athleteFilter`}
            placeholder={intl.formatMessage(
              messages.resultsTableHeaderSelectAthletes
            )}
            onChange={(option, action) => {
              onChangeAthleteCol(option, action);
            }}
            label={intl.formatMessage(
              messages.resultsTableHeaderFilterByAthlete
            )}
            options={usersArray.current.map(u => ({
              value: u.id,
              label: u.label
            }))}
            isClearable
            isMulti
            styles={{
              container: { width: '100%' },
              option: { textAlign: 'left' }
            }}
            labelHiddenVisually
            // customMultiValue
          /> */}
          <Button
            tiny
            inactive={true}
            onClick={() => {
              onSortAthletes();
            }}
            disabled={false}
          >
            <Icon
              id={
                athletesSortDirection
                  ? `sort-${athletesSortDirection ? 'down' : 'up'}`
                  : 'sort'
              }
            />
          </Button>
        </RefactoredTableHeaderCell>
        {cols.map((col, i) => {
          const isSortIndex = i === sortIndex;

          return (
            <TableHeaderCell
              //   sortedBy={i === 2} // TODO: @Maarten, `sortIndex` zowel in TableBody's TableCell als in TableHeader's TableCell
              mainCell
              key={col.id}
              style={{
                width: isBasic ? col.widthExpanded : col.width,
                padding: isBasic ? '0 16px' : '0 6px',
                boxSizing: 'content-box'
              }}
            >
              <div
                className="r-table-cell--complex-label"
                dangerouslySetInnerHTML={{
                  __html: sanitize(col.label, {
                    ALLOWED_TAGS: ['span', 'b', 'i', 'br']
                  })
                }}
              />
              <Button
                tiny
                disabled
                inactive={!isSortIndex}
                onClick={() => {
                  const newDirection = !isSortIndex
                    ? SORT_TYPES.DOWN
                    : col.sortDirection === SORT_TYPES.DOWN
                      ? SORT_TYPES.UP
                      : SORT_TYPES.DOWN;
                  onSort(i, newDirection);
                }}
                // disabled={!col.id}
              >
                <Icon id={isSortIndex ? `sort-${col.sortDirection}` : 'sort'} />
              </Button>
            </TableHeaderCell>
          );
        })}
      </TableRow>
    </RefactoredTableHeader>
  );
};

export default GrowthTableHeader;
