import React from 'react';
import { MUTATION_MAIL_SESSION_REPORT } from 'services/aws/session-query';
import Icon from 'components/icon/Icon';
import ConfirmMutationButton from 'containers/partials/buttons/ConfirmMutationButton';
import messages from 'messages';
import { MODAL_TYPES } from 'models/ModalData';
import { useNotificationQueue } from 'components/notification';
import { FormattedMessage, useIntl } from 'react-intl';

const MailReportButton = ({
  testDataId,
  entityId,
  session,
  sporter,
  sessionId,
  options,
  children,
  ...props
}) => {
  const notification = useNotificationQueue();
  const intl = useIntl();

  const hasEmail = sporter?.meta?.email || sporter?.email;
  const hasTestDataId = !!testDataId;

  if (session.isIAm || session.isHanSisp) return <></>;
  // if ((!sporter?.meta?.email && !sporter.email) || !testDataId) return <></>;

  const { benchmark, ...restOptions } = options;
  const data = {
    testDataId,
    options: restOptions
  };
  if (!session.isRehab && !session.isPrevention) {
    data.benchmarkId = benchmark;
  }

  console.log('MailReportButton');
  console.log(
    '(!sporter.isHanOrg && !session.isKlusce)',
    !sporter.isHanOrg,
    !session.isKlusce
  );
  console.log('session.isILike ', session.isILike);

  if (
    !sporter.isHanOrg ||
    (sporter.isHanOrg && !session.isKlusce) ||
    session.isILike
  )
    return <></>;

  console.log('Show MailReportButton');

  return (
    <ConfirmMutationButton
      {...props}
      mutation={MUTATION_MAIL_SESSION_REPORT}
      confirmTitle={messages.modalConfirmMailReportTitle}
      confirmMessage={messages.modalConfirmMailReportMessage}
      confirmAcceptLabel={messages.modalConfirmMailReportAcceptButton}
      confirmType={MODAL_TYPES.WARNING}
      variables={data}
      update={(cache, { data: { mailSessionReport } }) => {
        if (mailSessionReport) {
          notification.add('mailSessionReport', {
            message: intl.formatMessage(
              messages.modalReportSendToSporterSuccessMessage
            )
          });
        }
      }}
      disabled={(!sporter?.meta?.email && !sporter.email) || !testDataId}
      dataQa={'mail-session-report'}
    >
      <Icon id="send" />
      {!hasEmail ? (
        <FormattedMessage
          {...messages.menuItemSessionMailReportDisabledEmail}
        />
      ) : !hasTestDataId ? (
        <FormattedMessage
          {...messages.menuItemSessionMailReportDisabledTestData}
        />
      ) : (
        <FormattedMessage {...messages.menuItemSessionMailReport} />
      )}
    </ConfirmMutationButton>
  );
};

export default MailReportButton;
