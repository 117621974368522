import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';
import { StoreContext } from 'index';
import Button from 'components/button/Button';

const MailAllReportsModalButton = ({
  entityId,
  session,
  options,
  children,
  onClick,
  ...props
}) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);

  console.log('MailAllReportsModalButton');
  console.log('is han', user.isHanOrg);
  console.log('is ido', session.isIDo);
  console.log('is ilike', session.isILike);
  console.log('is isIAm', session.isIAm);
  console.log('is isDjas', session.isDjas);
  console.log('is isHan', session.isHan);
  console.log('is isHanSisp', session.isHanSisp);
  console.log('is isKlusce', session.isKlusce);
  console.log('is isRecurring', session.isRecurring);
  console.log('user isHanOrg', user.isHanOrg);

  if (
    session.isIDo ||
    session.isILike ||
    session.isIAm ||
    session.isDjas ||
    session.isHan ||
    user.isHanOrg ||
    session.isHanSisp ||
    session.isKlusce
  )
    return <></>;

  if (session.isPrevention || session.isRehab) return <></>;

  if (session.isRecurring) return <></>;

  console.log('Show MailAllReportsModalButton');

  return (
    <Button menu onClick={onClick} {...props}>
      <Icon id="send" />
      <FormattedMessage {...messages.menuItemSessionMailAllReportsToPersons} />
    </Button>
  );
};

export default MailAllReportsModalButton;
