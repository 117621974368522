import React, { useEffect, useState, useContext } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import ProgramsList from 'containers/partials/lists/ProgramsList';
import { useQuery } from '@apollo/client';
import { QUERY_GET_PROGRAMS_BY_PERSON } from 'services/aws/programs-query';
import { sort, SORT_DATA_TYPES } from 'utils/sort';
import { parsePrograms } from 'utils/programs';
import { StoreContext } from 'index';
import { FormattedMessage, useIntl } from 'react-intl';
import { ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM } from 'routes/RouteList';
import Loader from 'components/loader/Loader';
import messages from 'messages';

const PersonPrograms = ({ personId }) => {
  const {
    uiState: { locale }
  } = useContext(StoreContext);

  const { push } = useHistory();
  const { entityId, groupId } = useParams();
  const intl = useIntl();
  const [search, setSearch] = useState('');
  const [programsData, setProgramsData] = useState([]);
  const [programs, setPrograms] = useState(null);

  const { loading, data } = useQuery(QUERY_GET_PROGRAMS_BY_PERSON, {
    variables: { entityId, personId },
    skip: !personId
  });

  useEffect(() => {
    if (
      data?.getExerciseProgramsByPerson &&
      data.getExerciseProgramsByPerson.length > 0
    ) {
      setProgramsData(
        parsePrograms(data.getExerciseProgramsByPerson, intl, locale)
      );
    }
  }, [data]);

  useEffect(() => {
    if (programsData.length > 0) {
      setPrograms(
        sort(
          programsData.filter(p =>
            p.title.toLowerCase().includes(search.toLowerCase())
          ),
          {
            keys: [
              { key: 'order', dataType: SORT_DATA_TYPES.NUMBER },
              { key: 'sortDate', dataType: SORT_DATA_TYPES.NUMBER }
            ]
          }
        )
      );
    } else {
      setPrograms([]);
    }
  }, [search, programsData]);

  const onSearch = e => {
    setSearch(e.target.value);
  };

  const onClickHandler = program => {
    console.log(generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM, {
      entityId,
      groupId,
      sporterId: personId,
      programId: program.id
    }))
    push(
      generatePath(ROUTE_SPORTERS_ENTITY_SPORTER_PROGRAMS_ENTITY_PROGRAM, {
        entityId,
        groupId,
        sporterId: personId,
        programId: program.id
      })
    );
  };

  if (loading) return <Loader />;

  return (
    <ProgramsList
      programs={programs}
      entityId={entityId}
      onClick={program => onClickHandler(program)}
      type={`user`}
      emptyMessage={<FormattedMessage {...messages.athleteProgramsEmpty} />}
      onSearch={onSearch}
    />
  );
};

export default PersonPrograms;
