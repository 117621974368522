import { generatePath, useHistory, useParams } from 'react-router-dom';
import { TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import {
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA,
  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT
} from 'routes/RouteList';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Badge from 'components/badge/Badge';
import ReportCard from 'containers/partials/cards/ReportCard';
import EditTestResultCard from 'containers/partials/cards/EditTestResultCard';
import { PreviewModalProvider } from 'contexts/PreviewModalContext';
import TrainingAdvice from 'containers/pages/prevention/TrainingAdvice';
import { useRouteMatch } from 'react-router-dom';
import useTabIndex from 'hooks/utils/useTabIndex';
import { useTestDataContext } from 'contexts/TestDataContext';
import TabLink from 'components/tabs/TabLink';
import Loader from 'components/loader/Loader';
import useEdit from 'hooks/utils/useEdit';
import PersonCard from 'containers/partials/cards/PersonCard';
import { EntityStoreProvider } from 'containers/pages/persons/store/EntityStoreContext';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

const PersonTabs = () => {
  const { entityId, sessionsTab, sessionId, sporterId, testDataId, programId } =
    useParams();
  const { push } = useHistory();
  const { testDataLoading, hasResults } = useTestDataContext();
  const person = useEntityContext(s => s.person);
  const [edit, setEdit] = useEdit(
    !!useRouteMatch(
      [
        ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT,
        ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT
      ],
      true
    )
  );

  const isAdviceView = useRouteMatch(
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE
  );

  const tabRoutes = [
    [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA
    ],
    [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA_EDIT
    ],
    [
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL,
      ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL_EDIT
    ],
    ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE
  ];

  const [tabIndex, setTabIndex] = useTabIndex(tabRoutes);

  const onCancel = () => {
    push(
      generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL, {
        sessionId,
        sessionsTab,
        entityId,
        sporterId
      })
    );
  };

  return (
    <Tabs
      selectedIndex={tabIndex}
      onSelect={index => setTabIndex(index)}
      fullWidth={isAdviceView}
    >
      <TabList>
        <TabLink
          key="session.sporter.report"
          to={
            testDataId
              ? generatePath(
                  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT_TESTDATA,
                  {
                    entityId,
                    sessionsTab,
                    sessionId,
                    sporterId,
                    testDataId
                  }
                )
              : generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_REPORT, {
                  entityId,
                  sessionsTab,
                  sessionId,
                  sporterId
                })
          }
          disabled={!hasResults()}
        >
          <FormattedMessage {...messages.tabSessionSporterReport} />
        </TabLink>
        <TabLink
          key="session.sporter.data"
          to={
            testDataId
              ? generatePath(
                  ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS_TESTDATA,
                  {
                    sessionId,
                    sessionsTab,
                    entityId,
                    sporterId,
                    testDataId
                  }
                )
              : generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_TESTS, {
                  entityId,
                  sessionsTab,
                  sessionId,
                  sporterId
                })
          }
        >
          <FormattedMessage {...messages.tabSessionSporterTests} />
        </TabLink>
        <TabLink
          key="session.sporter.global"
          to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_GLOBAL, {
            entityId,
            sessionsTab,
            sessionId,
            sporterId
          })}
        >
          <FormattedMessage {...messages.tabSessionGlobal} />
        </TabLink>
        {isAdviceView && programId && (
          <TabLink
            key="session.sporter.advice"
            to={generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER_ADVICE, {
              entityId,
              sessionsTab,
              sessionId,
              sporterId,
              programId
            })}
          >
            <Badge>
              <FormattedMessage {...messages.badgeSessionSporterAdvice} />
            </Badge>
            <FormattedMessage {...messages.tabSessionSporterAdvice} />
          </TabLink>
        )}
      </TabList>

      {/* Tab > REPORT */}

      <TabPanel key="session.sporter.report">
        <TabPanelBody withScroller>
          {testDataLoading ? (
            <Loader />
          ) : (
            <ReportCard
              compressed
              rootPath={generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER, {
                entityId,
                sessionId,
                sessionsTab,
                sporterId
              })}
            />
          )}
        </TabPanelBody>
      </TabPanel>

      {/* Tab > DATA */}
      <TabPanel key="session.sporter.data">
        <TabPanelBody withScroller>
          {testDataLoading ? (
            <Loader />
          ) : (
            <EditTestResultCard
              entityId={entityId}
              sporterId={sporterId}
              edit={edit}
              rootPath={generatePath(ROUTE_SESSIONS_ENTITY_SESSION_SPORTER, {
                entityId,
                sessionId,
                sessionsTab,
                sporterId
              })}
            />
          )}
        </TabPanelBody>
      </TabPanel>
      {/* Tab > GLOBAL */}
      <TabPanel key="session.sporter.global">
        <TabPanelBody withScroller>
          <EntityStoreProvider>
            <PersonCard
              entityId={entityId}
              person={person}
              onCancel={onCancel}
            />
          </EntityStoreProvider>
        </TabPanelBody>
      </TabPanel>
      {/* Tab > ADVICE */}
      {isAdviceView && (
        <TabPanel key="session.sporter.advice">
          <PreviewModalProvider entityId={entityId}>
            <TrainingAdvice
              entityId={entityId}
              sessionId={sessionId}
              sporterId={sporterId}
              // testDataId={testData?.id}
            />
          </PreviewModalProvider>
        </TabPanel>
      )}
    </Tabs>
  );
};

export default PersonTabs;
