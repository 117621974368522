import React, { createContext, useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useSessionContext } from 'contexts/SessionContext';
import { FinishedState } from 'enums';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  MUTATION_START_TEST_SESSION,
  MUTATION_UPDATE_TEST_DATA,
  QUERY_GET_TESTDATA
} from 'services/aws/session-query';
import TestData from 'models/TestData';
import { groupTestResults } from 'utils/tests';
import { useNotificationQueue } from 'components/notification';
import { useIntl } from 'react-intl';
import messages from 'messages.js';
import { getToday } from 'utils/date';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { StoreContext } from 'index';
import structuredClone from '@ungap/structured-clone';

export const TestDataContext = createContext();

const TestDataProvider = ({ children }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);
  const intl = useIntl();
  const { entityId, testDataId, sporterId } = useParams();
  const { session, refetch, options } = useSessionContext();
  const [testData, setTestData] = useState(new TestData({}));
  const [groupedResults, setGroupedResults] = useState([]);
  const [formData, setFormData] = useState({});
  const [formIsUpdated, setFormIsUpdated] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState(false);
  const notification = useNotificationQueue();
  const [activeDate, setActiveDate] = useState();

  const [
    getTestData,
    { data, loading: testDataLoading, refetch: refetchTestData }
  ] = useLazyQuery(QUERY_GET_TESTDATA);

  const [updateTestData] = useMutation(MUTATION_UPDATE_TEST_DATA);
  const [startTestSession] = useMutation(MUTATION_START_TEST_SESSION);

  useEffect(() => {
    if (data?.getTestDataV2) {
      const testDataResult = new TestData(data.getTestDataV2);
      const groupedResults =
        Array.isArray(testDataResult.result) &&
        groupTestResults(filterGrowthPredictionData(testDataResult.result));
      setGroupedResults(groupedResults);

      const sessionFormData = session.getFormData();
      // merge with the session formData
      setFormData({ ...sessionFormData, ...testDataResult.formData });
      setActiveDate(testDataResult.testDataDate);
      setTestData(testDataResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const createTestData = async date => {
    const testData = await startTestSession({
      variables: {
        personId: sporterId ?? user.id,
        testSessionId: session.id,
        finished: FinishedState.STARTED,
        testDataDate: date ?? getToday()
      }
    });

    if (testData?.data?.addTestData) {
      await refetch({ testSessionId: session.id });
      return testData.data.addTestData.id;
    }

    return null;
  };

  const fetchTestData = async (sessionId, testDataId) => {
    const variables = {
      entityId,
      testDataId,
      testSessionId: session.id
    };
    if (options.benchmark && !(session.isRehab || session.isPrevention)) {
      variables.benchmarkId = options.benchmark;
    }
    if (entityId && sessionId && testDataId) {
      await getTestData({
        variables,
        fetchPolicy: 'no-cache'
      });
    }
  };

  const findTestDataByDate = async testDataDate => {
    if (session?.id && testDataDate) {
      setActiveDate(testDataDate);
      const dateFormatted = format(new Date(testDataDate), 'yyyy-LL-dd');
      const testData = session.testData.find(
        testData =>
          testData.testDataDate === dateFormatted &&
          testData.personId === sporterId
      );

      if (testData) {
        return testData;
      } else {
        return false;
      }
    }
  };

  const setNewActiveDate = async testDataDate => {
    if (testDataDate !== activeDate) {
      // setNewSelectedDate(testDataDate);
      setActiveDate(testDataDate);
    }
  };

  const unsetAllData = () => {
    setFormData({});
    setTestData(new TestData({}));
    setFormIsUpdated(false);
  };

  const updateFormData = (testId, testItem, testDataId, data) => {
    const tempData = unNullifyData(data);
    setFormData({ ...tempData });
  };

  // const submitData = (testId, testItem, testDataId, data) => {
  const submitData = async testDataId => {
    let formValues = structuredClone(formData);

    Object.keys(formValues).forEach(key => {
      Object.keys(formValues[key]).forEach(laterality => {
        if (Array.isArray(formValues[key][laterality])) {
          formValues[key][laterality] = [
            ...formData[key][laterality].map(v => {
              return v !== '' && typeof v !== 'object' && !isNaN(Number(v))
                ? Number(v)
                : null;
            })
          ];
        } else {
          formValues[key][laterality] = [''];
        }
      });

      const testItemValue = {
        [key]: {
          [key]: {
            ...formValues[key]
          }
        }
      };
      formValues = { ...formValues, ...testItemValue };
    });

    return await updateTestData({
      variables: {
        id: testDataId,
        data: JSON.stringify(formValues),
        finished: FinishedState.STARTED
      }
    })
      .then(result => {
        setFormIsDirty(false);
        setFormIsUpdated(true);
        notification.add(result.data.editTestData.id, {
          message: intl.formatMessage(messages.messageTestdataSaved)
        });
        return result;
      })
      .catch(e => {
        notification.add(`testDataError${testDataId}`, {
          message: intl.formatMessage(messages.messageTestdataSaveError),
          level: 'error'
        });
        Sentry.captureException(e);
        return e;
      });
  };

  const hasResults = () => {
    if (testData?.id) {
      return testData?.finishedPercentage > 0;
    } else {
      return false;
      // TODO this check is wrong, not sure why it was here, finishedPercentage is not a correct check
      /*
      const sessionTestData = session.getLastTestData(sporterId);
      if (sessionTestData?.id) {
        return sessionTestData?.finishedPercentage > 0;
      } else {
        return false;
      }
      */
    }
  };

  return (
    <TestDataContext.Provider
      value={{
        testDataId,
        testData,
        hasResults,
        testDataLoading,
        formData,
        groupedResults,
        fetchTestData,
        refetchTestData,
        updateFormData,
        submitData,
        createTestData,
        formIsUpdated,
        formIsDirty,
        setFormIsDirty,
        activeDate,
        setActiveDate,
        findTestDataByDate,
        setNewActiveDate,
        unsetAllData
      }}
    >
      {children}
    </TestDataContext.Provider>
  );
};

function useTestDataContext() {
  const context = useContext(TestDataContext);
  if (context === undefined) {
    throw new Error(
      'The TestDataContext hook must be used within a TestDataProvider.Provider'
    );
  }
  return context;
}

export { TestDataProvider, useTestDataContext };

const filterGrowthPredictionData = tests => {
  const growIds = [
    '5737716d-7eb3-4724-85d2-7baa4652XXXX', // 'Bio Age (K-R)',
    'c4141f44-42ad-4620-bbd4-7d4f257f8d1b', // 'Biological Age (M)',
    'face72bd-d260-44a3-b55c-3bae9e3d18dc', // '% Adult Height Prediction',
    '496d355a-4667-4253-8701-6a26bc7a3f59', // 'APHV',
    '7497420b-4a4f-4958-971e-f68aa41cbbe9', // 'Years from APHV (Maturity Offset)',
    '05c16b3e-0f75-4f17-a837-0d6163b0fd22', // 'Adult Height Prediction (M)',
    'e4e9be42-7890-4188-8876-9f0c638fdcac', // 'Adult Height Prediction (M) v2',
    '5737716d-7eb3-4724-85d2-7baa4652ae93', // 'Adult Height Prediction (K-R)',
    '105b2d29-1469-4d27-bdc0-9d9b6c44b915', // 'Development (K-R) Z-score',
    '0c58cad6-8a6f-402f-b30e-b00e06871f14', // 'Development Maturation Timing',
    '9a7ec084-cc6b-45fd-9140-8a30169c1de1' // 'Development (M)',
  ];

  return (
    Array.isArray(tests) &&
    tests.map(test => {
      if (growIds.indexOf(test.id) !== -1) {
        test.category = 'B_growthprediction';
      }
      return test;
    })
  );
};

const unNullifyData = data => {
  const newData = structuredClone({ ...data });
  Object.keys(newData).forEach(key => {
    Object.keys(newData[key]).forEach(laterality => {
      newData[key][laterality].map(value => {
        // eslint-disable-next-line default-case
        switch (typeof value) {
          case 'string':
            if (value === null || value === '') {
              return '';
            }
            break;
          case 'number':
            if (isNaN(value) || value === null) {
              return '';
            }
            break;
          case 'object':
            if (value === null) {
              return '';
            }
            break;
          default:
            return value;
        }
      });
    });
  });

  return newData;
};
