import React, { useEffect, useState } from 'react';
import { generatePath, useParams, useRouteMatch } from 'react-router-dom';
import {
  Panel,
  PanelBody,
  PanelHeader,
  PanelSubtitle,
  PanelTitle,
  PanelTitleButton,
  PanelTitleWrapper
} from 'components/panel';
import { Breadcrumb, Breadcrumbs } from 'components/breadcrumbs';
import { Tab, TabList, TabPanel, TabPanelBody, Tabs } from 'components/tabs';
import { useMedicalContext } from 'contexts/MedicalContext';
import { MenuWrapper } from 'components/menu';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import RemovePersonIssueButton from 'containers/partials/buttons/RemoveIssueButton';
import {
  ROUTE_SPORTERS_ENTITY,
  ROUTE_SPORTERS_ENTITY_SPORTER,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL,
  ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT
} from 'routes/RouteList';
import useTabIndex from 'hooks/utils/useTabIndex';
import { getLocalDateFormat } from 'utils/date';
import PersonIssue from 'models/PersonIssue';
import IssueDetailCard from 'containers/partials/cards/IssueDetailCard';
import { useEntityContext } from 'containers/pages/persons/store/useEntityContext';

function Issue() {
  const { entityId, groupId, sporterId, issueId } = useParams();
  const person = useEntityContext(s => s.person);
  const { medical } = useMedicalContext();
  const [personIssue, setPersonIssue] = useState(new PersonIssue({}));
  const active = useRouteMatch([
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT,
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL
  ]);
  const edit = useRouteMatch(
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL_EDIT
  )?.isExact;
  const [tabIndex, setTabIndex] = useTabIndex([
    ROUTE_SPORTERS_ENTITY_SPORTER_MEDICAL_ISSUE_DETAIL
  ]);

  useEffect(() => {
    if (medical?.issues) {
      const personIssue = medical.issues.find(i => i.id === issueId);
      if (personIssue) {
        setPersonIssue(personIssue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medical, issueId]);

  return (
    <Panel active={active}>
      {person && (
        <>
          <PanelHeader>
            <Breadcrumbs>
              <Breadcrumb
                to={generatePath(ROUTE_SPORTERS_ENTITY, { entityId, groupId })}
              >
                <FormattedMessage {...messages.breadcrumbSporters} />
              </Breadcrumb>
              <Breadcrumb
                to={generatePath(ROUTE_SPORTERS_ENTITY_SPORTER, {
                  entityId,
                  groupId,
                  sporterId
                })}
              >
                {`${person.firstname} ${person.lastname}`}
              </Breadcrumb>
            </Breadcrumbs>
            <PanelTitleWrapper>
              <PanelTitle>
                {personIssue.title}

                <MenuWrapper trigger={<PanelTitleButton />}>
                  <RemovePersonIssueButton
                    menu
                    entityId={entityId}
                    personId={person.id}
                    personIssueId={personIssue.id}
                  >
                    <FormattedMessage
                      {...messages.rehabMenuItemRemovePersonIssue}
                    />
                  </RemovePersonIssueButton>
                </MenuWrapper>
              </PanelTitle>
              <PanelSubtitle>
                {getLocalDateFormat(personIssue.injuryDate)}
                {personIssue.injuryEndDate && (
                  <>
                    {' - '}
                    {getLocalDateFormat(personIssue.injuryEndDate)}
                  </>
                )}
              </PanelSubtitle>
            </PanelTitleWrapper>
          </PanelHeader>
          <PanelBody>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={index => setTabIndex(index)}
            >
              <TabList>
                <Tab key="issue.detail">
                  <FormattedMessage {...messages.rehabIssueDetailsTab} />
                </Tab>
              </TabList>
              <TabPanel key="issue.detail">
                <TabPanelBody withScroller>
                  <IssueDetailCard
                    entityId={entityId}
                    personIssue={personIssue}
                    edit={edit}
                    medical={medical}
                    sporterId={sporterId}
                  />
                </TabPanelBody>
              </TabPanel>
            </Tabs>
          </PanelBody>
        </>
      )}
    </Panel>
  );
}

export default Issue;
