import GrowthTableHeader from 'containers/pages/growthtracker/table/GrowthTableHeader';
import { RefactoredTable, Table, TableBody } from 'components/table';
import { useGrowthPanelContext } from 'contexts/GrowthPanelContext';
import GrowthTableRow from 'containers/pages/growthtracker/table/GrowthTableRow';

const GrowthTable = () => {
  const { athletes, rows, cols } = useGrowthPanelContext();
  //   console.log('growthtable', athletes, rows, cols);

  return (
    <RefactoredTable variant={cols.length > 5 ? 'full-width' : 'fixed-width'}>
      <GrowthTableHeader />
      <TableBody>
        {athletes.map(user => {
          return (
            <GrowthTableRow
              key={user.id}
              user={user}
              cell={rows[user.id]}
              colsLength={cols.length}
            />
          );
        })}
      </TableBody>
    </RefactoredTable>
  );
};

export default GrowthTable;
