import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import { downloadAllReports } from 'containers/partials/modals/DownloadReportOptionsModal';
import { StoreContext } from 'index';
import { useSessionContext } from 'contexts/SessionContext';
import { openWindowWithLoader } from 'utils/browser';

const DownloadSessionsReportButton = ({ onClick }) => {
  const intl = useIntl();
  const {
    uiState,
    authStore: { user }
  } = useContext(StoreContext);
  const { session, options } = useSessionContext();

  const onClickHandler = async () => {
    if (user.isHylyghtOrg) {
      onClick(true);
    } else {
      const newWindow =
        !session.isTestSetV2 && session.isIAm ? openWindowWithLoader() : null;
      await downloadAllReports(session, newWindow, uiState, options, intl);
    }
  };

  console.log('DownloadSKSessionsReportButton');
  console.log('isTestSetV2', session.isTestSetV2);
  console.log('is han', user.isHanOrg);
  console.log('is ido', session.isIDo);
  console.log('!is isBenchmarked', session.isBenchmarked);
  console.log('is isRecurring', session.isRecurring);

  if (session.isTestSetV2 && (session.isILike || session.isIDo)) return <></>;

  if (user.isHanOrg) return <></>;

  if (!session.isBenchmarked) return <></>;

  if (session.isRecurring) return <></>;

  if (session.isILike || session.isIDo) return <></>;

  console.log('Show DownloadSessionsReportButton');

  return (
    <Button menu onClick={onClickHandler}>
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemSessionDownloadAllReports} />
    </Button>
  );
};

export default DownloadSessionsReportButton;
