import React, { useContext } from 'react';
import {
  CardHeader,
  CardHeaderText,
  CardHeaderTitle
} from 'components/card/card-header';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import FormEditHeaderButtons from 'containers/partials/buttons/FormEditHeaderButtons';
import CardBody from 'components/card/CardBody';
import classNames from 'classnames';
import FieldInput from 'components/input/FieldInput';
import LanguageSelect from 'components/input/LanguageSelect';
import FileUppyWrapper from 'containers/partials/file-upload/FileUppyWrapper';
import {
  ContactPersonTypes,
  FILE_TYPES,
  OTHER_VALUE,
  SettingsOrganisationTypes
} from 'constants.js';
import Icon from 'components/icon/Icon';
import CountrySelect from 'components/input/CountrySelect';
import Select, { Option } from 'components/input/Select';
import { FieldTextarea } from 'components/input/FieldTextarea';
import useEdit from 'hooks/utils/useEdit';
import { StoreContext } from 'index';
import { useFormikContext } from 'formik';
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_FILE } from 'services/aws/file-upload';
import { useAbility } from '@casl/react';
import { AbilityContext } from 'Can';

function OrganisationFormInput({ organisation, ...props }) {
  const ability = useAbility(AbilityContext);
  const intl = useIntl();
  const {
    authStore: {
      user,
      user: { rootEntityId },
      entity
    }
  } = useContext(StoreContext);
  const {
    values,
    errors,
    isValid,
    isSubmitting,
    submitForm,
    setFieldValue,
    resetForm
  } = useFormikContext();

  const [edit, setEdit] = useEdit(props.edit, true);

  const [getFile] = useLazyQuery(QUERY_GET_FILE, {
    fetchPolicy: 'network-only'
  });

  const onFileUploaded = async files => {
    const result = await Promise.all(
      files.map(async file => {
        const exerciseFile = await getFile({
          variables: { fileId: file.id }
        });
        if (exerciseFile?.data?.getFile) {
          return exerciseFile.data.getFile;
        }
      })
    );

    if (result[0]) {
      setFieldValue('info.logoLinkId', result[0].id);
      setFieldValue('info.logo', result[0].signedDownloadUrl);
    }
  };

  return (
    <>
      <CardHeader secondary extraClassNames="o-flex--wrap">
        <CardHeaderText>
          <CardHeaderTitle>
            <FormattedMessage
              {...messages.organisationsTitleOrganisationDetails}
            />
          </CardHeaderTitle>
        </CardHeaderText>
        {ability.can('manage', 'Organisation') && (
          <FormEditHeaderButtons
            edit={edit}
            setEdit={setEdit}
            onSubmit={submitForm}
            disabledSubmit={!isValid || isSubmitting}
            onCancel={() => {
              resetForm();
            }}
            extraClassNames="u-margin-bottom-small"
          />
        )}
      </CardHeader>

      <CardBody secondary separatorBottom>
        <div
          className={classNames('o-layout o-layout--small', {
            'u-margin-bottom-medium': edit,
            'u-margin-bottom-small': !edit
          })}
        >
          <div
            className={classNames('o-layout__item', {
              'u-1-of-2': !edit,
              'u-2-of-3': edit
            })}
          >
            <FieldInput
              id="name"
              name="name"
              type="text"
              errors={errors}
              readOnly={!edit}
              required
            >
              <FormattedMessage {...messages.organisationsNameLabel} />
            </FieldInput>

            {(edit || organisation.baseLanguage) && (
              <LanguageSelect
                id="language"
                name="language"
                readOnly={!edit}
                label={intl.formatMessage(messages.labelUserLanguage)}
                possibleLanguages={entity.possibleLanguages}
                value={values.baseLanguage}
                onSelect={language => {
                  setFieldValue('baseLanguage', language.value);
                }}
              />
            )}
          </div>
          <div
            className={classNames('o-layout__item o-flex', {
              'u-1-of-2': !edit,
              'u-1-of-3': edit
            })}
          >
            <div className="c-input__group o-flex o-flex--column">
              <label className="c-input__label">
                {<FormattedMessage {...messages.organisationsLogoLabel} />}
              </label>
              <div
                className={classNames(
                  'c-file-upload-wrapper c-file-upload-wrapper--one-image',
                  'c-file-upload--state',
                  {
                    'c-file-upload-wrapper--one-image': true,
                    'c-file-upload-wrapper--readonly': !edit
                  }
                )}
              >
                <div className="c-media">
                  {edit && organisation.id ? (
                    <FileUppyWrapper
                      entityId={rootEntityId}
                      linkId={organisation.id}
                      type={'org_logo'}
                      accept={[...FILE_TYPES.IMAGE]}
                      onFilesUploaded={files => onFileUploaded(files)}
                      maxFiles={1}
                      isPublic
                    >
                      {organisation.info.logo ? (
                        <img
                          src={organisation.info.logo}
                          alt={organisation.name}
                        />
                      ) : (
                        <div className="c-media__empty-message">
                          <Icon
                            id="no-img"
                            hugeIcon
                            fillColor="color-neutral"
                            extraClassNames="u-margin-auto"
                          />
                          <FormattedMessage
                            {...messages.organisationsAddLogoPlaceholder}
                          />
                        </div>
                      )}
                    </FileUppyWrapper>
                  ) : organisation.info.logo ? (
                    <img src={organisation.info.logo} alt={organisation.name} />
                  ) : (
                    <div className="c-media__empty-message">
                      <Icon
                        id="no-img"
                        hugeIcon
                        fillColor="color-neutral"
                        extraClassNames="u-margin-auto"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="o-layout o-layout--small">
          {(edit || organisation.info.address_1) && (
            <div className="o-layout__item">
              <FieldInput
                id="address_1"
                name="info.address_1"
                type="text"
                readOnly={!edit}
                placeholder={intl.formatMessage(
                  messages.organisationsAddress1Placeholder
                )}
              >
                <FormattedMessage {...messages.organisationsAddressLabel} />
              </FieldInput>
            </div>
          )}

          {(edit || organisation.info.address_2) && (
            <div
              className={classNames('o-layout__item', {
                'u-2-of-3': edit,
                'u-1-of-2': !edit
              })}
            >
              <FieldInput
                id="address_2"
                name="info.address_2"
                type="text"
                readOnly={!edit}
                placeholder={intl.formatMessage(
                  messages.organisationsAddress2Placeholder
                )}
              >
                <FormattedMessage {...messages.organisationsAddress2Label} />
              </FieldInput>
            </div>
          )}

          {(edit || organisation.info.zipcode) && (
            <div
              className={classNames('o-layout__item', {
                'u-1-of-3': edit,
                'u-1-of-2': !edit
              })}
            >
              <FieldInput
                id="zipcode"
                name="info.zipcode"
                type="text"
                readOnly={!edit}
              >
                <FormattedMessage {...messages.organisationsZipcodeLabel} />
              </FieldInput>
            </div>
          )}

          {(edit || organisation.info.location) && (
            <div className="o-layout__item u-1-of-2">
              <FieldInput
                id="location"
                name="info.location"
                type="text"
                readOnly={!edit}
              >
                <FormattedMessage {...messages.organisationsLocationLabel} />
              </FieldInput>
            </div>
          )}

          {(edit || organisation.info.country) && (
            <div className="o-layout__item u-1-of-2">
              <CountrySelect
                id="country"
                name="info.country"
                readOnly={!edit}
                label={intl.formatMessage(messages.organisationsCountryLabel)}
                value={values.info.country}
                onSelect={country => {
                  setFieldValue('info.country', country.value);
                }}
              />
            </div>
          )}

          {!user.isHylyghtOrg && (
            <>
              {(edit ||
                (organisation.info.organisationType !== OTHER_VALUE &&
                  organisation.info.organisationType) ||
                (organisation.info.organisationType === OTHER_VALUE &&
                  !organisation.info.otherOrganisationType)) && (
                <div className="o-layout__item u-1-of-2">
                  <Select
                    label={intl.formatMessage(
                      messages.organisationsOrganisationTypeLabel
                    )}
                    name="info.organisationType"
                    readOnly={!edit}
                    value={values.info.organisationType}
                    onChange={option => {
                      setFieldValue('info.organisationType', option.value);
                    }}
                  >
                    {SettingsOrganisationTypes.map(cpt => (
                      <Option
                        key={cpt.value}
                        value={cpt.value}
                        label={intl.formatMessage(
                          messages[`organisationOrganisationType${cpt.label}`]
                        )}
                      />
                    ))}
                  </Select>
                </div>
              )}

              {((edit &&
                (organisation.info.organisationType === OTHER_VALUE ||
                  values.info.organisationType === OTHER_VALUE)) ||
                (!edit &&
                  organisation.info.organisationType === OTHER_VALUE &&
                  organisation.info.otherOrganisationType)) && (
                <div className="o-layout__item u-1-of-2">
                  <FieldInput
                    id="other-organisation-type"
                    name="info.otherOrganisationType"
                    type="text"
                    readOnly={!edit}
                  >
                    <FormattedMessage
                      {...messages.organisationsOrganisationTypeOtherLabel}
                    />
                  </FieldInput>
                </div>
              )}
            </>
          )}
        </div>
      </CardBody>

      {(edit ||
        organisation.info.contact.firstname ||
        organisation.info.contact.lastname ||
        organisation.info.contact.email ||
        organisation.info.contact.otherType ||
        organisation.info.contact.type ||
        organisation.info.contact.notes) && (
        <CardHeader secondary>
          <CardHeaderText>
            <CardHeaderTitle>
              <FormattedMessage
                {...messages.organisationsTitleOrganisationContactDetails}
              />
            </CardHeaderTitle>
          </CardHeaderText>
        </CardHeader>
      )}
      {(edit ||
        organisation.info.contact.firstname ||
        organisation.info.contact.lastname ||
        organisation.info.contact.email ||
        organisation.info.contact.otherType ||
        organisation.info.contact.type ||
        organisation.info.contact.notes) && (
        <CardBody secondary separatorBottom>
          <div className="o-layout o-layout--small">
            {(edit || organisation.info.contact.firstname) && (
              <div className="o-layout__item u-1-of-2">
                <FieldInput
                  id="firstname"
                  name="info.contact.firstname"
                  type="text"
                  readOnly={!edit}
                >
                  <FormattedMessage {...messages.labelUserFirstname} />
                </FieldInput>
              </div>
            )}
            {(edit || organisation.info.contact.lastname) && (
              <div className="o-layout__item u-1-of-2">
                <FieldInput
                  id="lastname"
                  name="info.contact.lastname"
                  type="text"
                  readOnly={!edit}
                >
                  <FormattedMessage {...messages.labelUserLastname} />
                </FieldInput>
              </div>
            )}
            {(edit || organisation.info.contact.email) && (
              <div className="o-layout__item">
                <FieldInput
                  id="email"
                  name="info.contact.email"
                  type="email"
                  readOnly={!edit}
                  /*icon={valid ? 'validation-check' : 'validation-error'}*/
                >
                  <FormattedMessage {...messages.labelUserEmail} />
                </FieldInput>
              </div>
            )}

            {(edit ||
              (organisation.info.contact.type !== OTHER_VALUE &&
                organisation.info.contact.type) ||
              (organisation.info.contact.type === OTHER_VALUE &&
                !organisation.info.contact.otherType)) &&
              !user.isHylyghtOrg && (
                <div className="o-layout__item">
                  <Select
                    label={intl.formatMessage(messages.organisationsTypeLabel)}
                    id="type-id"
                    name="info.contact.type"
                    readOnly={!edit}
                    value={values.info.contact.type}
                    onChange={option => {
                      const isOther = option.value === OTHER_VALUE;
                      setFieldValue('info.contact.type', option.value);
                      setFieldValue(
                        'info.contact.otherType',
                        isOther ? '' : organisation.info.contact.otherType
                      );
                    }}
                  >
                    {ContactPersonTypes.map(cpt => (
                      <Option
                        key={cpt.value}
                        value={cpt.value}
                        label={intl.formatMessage(
                          messages[`organisationContactType${cpt.label}`]
                        )}
                      />
                    ))}
                  </Select>
                </div>
              )}
            {((edit &&
              (organisation.info.contact.type === OTHER_VALUE ||
                values.info.contact.type === OTHER_VALUE)) ||
              (!edit &&
                organisation.info.contact.type === OTHER_VALUE &&
                organisation.info.contact.otherType)) &&
              !user.isHylyghtOrg && (
                <div className="o-layout__item">
                  <FieldInput
                    id="other-type"
                    name="info.contact.otherType"
                    type="text"
                    readOnly={!edit}
                    onChange={e => {
                      setFieldValue('info.contact.otherType', e.target.value);
                    }}
                  >
                    {!edit &&
                      intl.formatMessage(messages.organisationsTypeLabel)}
                  </FieldInput>
                </div>
              )}

            <div className="o-layout__item">
              {edit ? (
                <FieldTextarea
                  id="notes-id"
                  name="info.contact.notes"
                  rows="4"
                  resizeVertical
                >
                  <FormattedMessage {...messages.organisationNotesLabel} />
                </FieldTextarea>
              ) : (
                organisation.info.contact.notes && (
                  <>
                    <p className={'c-input__label'}>
                      <FormattedMessage {...messages.cardLabelNotes} />
                    </p>
                    <p>{organisation.info.contact.notes}</p>
                  </>
                )
              )}
            </div>
          </div>
        </CardBody>
      )}
    </>
  );
}

export default OrganisationFormInput;
