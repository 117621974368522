import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import { exportRawSession } from 'services/aws/session-query';
import { navigateToUrl } from 'utils/browser';
import { StoreContext } from 'index';

const ExportReportButton = ({ session }) => {
  const { uiState } = useContext(StoreContext);

  const exportSessionHandler = async () => {
    uiState.increasePendingRequest();
    const filename = await exportRawSession(session.id);
    if (filename) {
      navigateToUrl(filename);
    }
    uiState.decreasePendingRequest();
  };

  console.log('SESSION', session);

  console.log('ExportReportButton');
  console.log('is Iam || ', session.isIAm);
  console.log('is isDjas', session.isDjas);

  if (session.isIAm || session.isDjas) return <></>;

  console.log('Show ExportReportButton');

  return (
    <Button menu onClick={exportSessionHandler}>
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemSessionExportSession} />
    </Button>
  );
};

export default ExportReportButton;
