import gql from 'graphql-tag';
import { FinishedState } from 'enums';
import { appSyncClient } from './app-sync';
import { gqlMutation } from './utils/mutate';
import { getGQLResult } from './utils/get';
import * as Sentry from '@sentry/react';

export const QUERY_GET_SESSION_BY_ID = gql`
  query getTestSession($testSessionId: ID!, $entityId: ID!) {
    getTestSession(testSessionId: $testSessionId, entityId: $entityId) {
      id
      name
      personEntities {
        id
        person {
          id
          firstname
          lastname
          gender
          birthdate
        }
      }
      testData {
        id
        personId
        finished
        finishedPercentage
        date_updated
        date_created
        testDataDate
      }
      testSets {
        id
        title
        note
        version
        originalId
        sport {
          id
          title
        }
        meta
        testSetVersion
        type
        config
        tests {
          id
          title
          category
          dependedTestIds
          laterality
          video_link
          mux_link
          duration
          protocol
          countdown
          timer
          dominant_only
          testItems {
            id
            title
            duration
            unit
            trials
            hidden
            laterality
            minimum
            maximum
            input_type
            input_type_parameters
            order
            showDecimals
            showScore
            showPercentage
          }
        }
        customReport
      }
      testSetIds
      testSetTemplateIds
      benchmark {
        id
      }
      startdate
      enddate
      location
      notes
      finishedPercentage
      finished
      meta
      #            config @client
      type
      preventionType

      recurring
      interval
    }
  }
`;

export const QUERY_GET_SESSIONS_FOR_TEST = gql`
  query getTestSession($testSessionId: ID!, $entityId: ID!) {
    getTestSession(testSessionId: $testSessionId, entityId: $entityId) {
      id
      name
      finished
      personEntities {
        id
        person {
          id
          firstname
          lastname
          gender
          birthdate
          uid
        }
      }
      testData {
        id
        personId
        finished
        finishedPercentage
      }
      testSets {
        id
        title
        type
        config
        testSetVersion
      }
      type
      preventionType
    }
  }
`;

export const getSessionForTest = async (entityId, sessionId) => {
  return await getGQLResult(appSyncClient, QUERY_GET_SESSIONS_FOR_TEST, {
    testSessionId: sessionId,
    entityId
  });
};

export const S_QUERY_GET_SESSIONS_BY_ENTITY_ID = gql`
  query getTestSessions($entityId: ID!, $testSetId: ID) {
    getTestSessions(entityId: $entityId, testSetId: $testSetId) {
      id
      name
      startdate
      enddate
      interval
      finished
      finishedPercentage
      testSetIds
      type
      preventionType
      meta
      recurring
    }
  }
`;

export const QUERY_GET_SESSIONS_BY_ENTITY_ID = gql`
  query getTestSessions($entityId: ID!, $testSetId: ID) {
    getTestSessions(entityId: $entityId, testSetId: $testSetId) {
      id
      name
      startdate
      finished
      finishedPercentage
      testSets {
        id
        title
        type
        config
        version
        testSetVersion
      }
      personEntities {
        id
      }
      type
      preventionType
      recurring
    }
  }
`;

export const QUERY_GET_SESSIONS_OF_SPORTER = gql`
  query getTestSessionsOfPerson($personId: ID!, $entityId: ID!) {
    getTestSessionsOfPerson(personId: $personId, entityId: $entityId) {
      id
      name
      startdate
      finished
      recurring
      testData {
        id
        personId
        finished
        finishedPercentage
      }
      #      testSets {
      #        id
      #        title
      #        type
      #        config
      #        testSetVersion
      #      }
    }
  }
`;

export const QUERY_GET_TOTAL_SESSIONS_OF_SPORTER = gql`
  query getTestSessionsOfPerson($personId: ID!, $entityId: ID!) {
    getTestSessionsOfPerson(personId: $personId, entityId: $entityId) {
      id
    }
  }
`;

export const QUERY_GET_TESTDATA_BY_ID = gql`
  query getTestData($testDataId: ID!, $entityId: ID!) {
    getTestData(testDataId: $testDataId, entityId: $entityId) {
      id
      personId
      person {
        id
        firstname
        lastname
        birthdate
      }
      data
      result
      finished
      finishedPercentage
      date_updated
      date_created
      notes {
        id
        linkId
        note
      }
    }
  }
`;

export const QUERY_GET_TESTDATA = gql`
  query getTestDataV2(
    $testDataId: ID
    $entityId: ID!
    $benchmarkId: ID
    $personId: ID
    $testSessionId: ID
  ) {
    getTestDataV2(
      testDataId: $testDataId
      entityId: $entityId
      benchmarkId: $benchmarkId
      personId: $personId
      testSessionId: $testSessionId
    ) {
      id
      resultBenchmarkId
      personId
      data
      result
      sports
      finished
      finishedPercentage
      testSetVersion
      date_updated
      date_created
      testDataDate
      notes {
        id
        linkId
        note
      }
    }
  }
`;

export const QUERY_GET_TESTDATA_BY_SESSION = gql`
  query getTestDataV2OfSession($testSessionId: ID!, $benchmarkId: ID) {
    getTestDataV2OfSession(
      testSessionId: $testSessionId
      benchmarkId: $benchmarkId
    ) {
      id
      resultBenchmarkId
      personId
      data
      # result
      # sports
      finished
      finishedPercentage
      testSetVersion
      date_updated
      date_created
      testDataDate
    }
  }
`;

export const MUTATION_ADD_TEST_SESSION = gql`
  mutation addTestSession(
    $name: String!
    $entityId: ID!
    $testSetId: ID
    $testSetIds: [ID]
    $benchmarkId: ID
    $startdate: AWSDate!
    $enddate: AWSDate
    $location: String
    $notes: String
    $meta: AWSJSON
    $type: TestSetV2Type
    $preventionType: PreventionType
    $recurring: Boolean
    $interval: RecurringInterval
  ) {
    addTestSession(
      input: {
        name: $name
        testSetId: $testSetId
        testSetIds: $testSetIds
        benchmarkId: $benchmarkId
        entityId: $entityId
        startdate: $startdate
        enddate: $enddate
        location: $location
        notes: $notes
        meta: $meta
        type: $type
        preventionType: $preventionType
        recurring: $recurring
        interval: $interval
      }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_TEST_SESSION = gql`
  mutation editTestSession(
    $id: ID!
    $entityId: ID!
    $testSetId: ID
    $testSetIds: [ID]
    $benchmarkId: ID
    $name: String!
    $startdate: AWSDate!
    $enddate: AWSDate
    $location: String
    $notes: String
    $meta: AWSJSON
    $type: TestSetV2Type
    $preventionType: PreventionType
    $recurring: Boolean
    $interval: RecurringInterval
  ) {
    editTestSession(
      input: {
        id: $id
        entityId: $entityId
        testSetId: $testSetId
        testSetIds: $testSetIds
        benchmarkId: $benchmarkId
        name: $name
        startdate: $startdate
        enddate: $enddate
        location: $location
        notes: $notes
        meta: $meta
        type: $type
        preventionType: $preventionType
        recurring: $recurring
        interval: $interval
      }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_TEST_SESSION_FINISHED = gql`
  mutation editTestSession(
    $id: ID!
    $entityId: ID!
    $name: String!
    $testSetId: ID!
    $finished: FinishedState
  ) {
    editTestSession(
      input: {
        id: $id
        entityId: $entityId
        name: $name
        finished: $finished
        testSetId: $testSetId
      }
    ) {
      id
    }
  }
`;

export const startTestSessionByClientId = async (personId, testSessionId) => {
  const mutation = MUTATION_START_TEST_SESSION;
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation,
      variables: {
        personId,
        testSessionId,
        finished: FinishedState.STARTED
      },

      update: (cache, { data: { addTestData } }) => {
        if (addTestData && addTestData.id) {
          resolve(addTestData.id);
        } else {
          Sentry.captureMessage('TestData is null');
          resolve(null);
        }
      },

      onError: err => {
        Sentry.captureException(err);
        resolve(null);
      }
    });
  });
};

export const MUTATION_START_TEST_SESSION = gql`
  mutation addTestData(
    $personId: ID!
    $testSessionId: ID!
    $finished: FinishedState!
    $testDataDate: AWSDate
  ) {
    addTestData(
      input: {
        personId: $personId
        testSessionId: $testSessionId
        finished: $finished
        testDataDate: $testDataDate
      }
    ) {
      id
    }
  }
`;

export const editTestSession = async ({ id, data, result, finished }) => {
  const mutation = MUTATION_UPDATE_TEST_DATA;
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation,
      variables: {
        id,
        data,
        result,
        finished
      },
      update: (cache, { data: { editTestData } }) => {
        if (editTestData && editTestData.id) {
          resolve(editTestData.id);
        }
      }
    });
  });
};

export const MUTATION_ADD_TEST_DATA = gql`
  mutation addTestData(
    $personId: ID!
    $testSessionId: ID
    $linkId: ID
    $finished: FinishedState!
    $testDataDate: AWSDate
  ) {
    addTestData(
      input: {
        personId: $personId
        testSessionId: $testSessionId
        linkId: $linkId
        finished: $finished
        testDataDate: $testDataDate
      }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_TEST_DATA = gql`
  mutation editTestData(
    $id: ID!
    $data: String
    $result: String
    $finished: FinishedState
  ) {
    editTestData(
      input: { id: $id, data: $data, result: $result, finished: $finished }
    ) {
      id
    }
  }
`;

export const MUTATION_UPDATE_TEST_DATA_DATA = gql`
  mutation editTestData($id: ID!, $data: String, $finished: FinishedState) {
    editTestData(input: { id: $id, data: $data, finished: $finished }) {
      id
    }
  }
`;

export const MUTATION_RECALCULATE_TESTDATA = gql`
  mutation recalculateTestData($testSessionId: ID!) {
    recalculateTestData(testSessionId: $testSessionId)
  }
`;

export const MUTATION_ADD_PERSONS_TO_TESTSESSION = gql`
  mutation addPersonsToTestSession($testSessionId: ID!, $personIds: [ID]) {
    addPersonsToTestSession(
      testSessionId: $testSessionId
      personIds: $personIds
    ) {
      id
    }
  }
`;

export const MUTATION_REMOVE_PERSONS_FROM_SESSION = gql`
  mutation removePersonsFromTestSession($testSessionId: ID!, $personIds: [ID]) {
    removePersonsFromTestSession(
      testSessionId: $testSessionId
      personIds: $personIds
    ) {
      id
    }
  }
`;

export const MUTATION_REMOVE_SESSION = gql`
  mutation archiveTestSession($testSessionId: ID!) {
    archiveTestSession(testSessionId: $testSessionId)
  }
`;

export const downloadAllSessionReports = async (
  testSessionId,
  benchmarkId,
  options
) => {
  return await getGQLResult(appSyncClient, QUERY_DOWNLOAD_ALL_SESSION_REPORTS, {
    testSessionId,
    benchmarkId,
    options
  });
};

export const downloadSessionReport = async ({
  testDataId,
  benchmarkId,
  options
}) => {
  return await getGQLResult(appSyncClient, QUERY_GENERATE_SESSION_REPORT, {
    testDataId,
    benchmarkId,
    options
  });
};

export const exportRawSession = async testSessionId => {
  const mutation = MUTATION_EXPORT_SESSION_CSV;
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation,
      variables: {
        testSessionId
      },
      update: (cache, { data: { exportTestSession } }) => {
        if (exportTestSession) {
          resolve(exportTestSession);
        }
      }
    });
  });
};

export const downloadSkReports = async testSessionId => {
  return await getGQLResult(
    appSyncClient,
    QUERY_DOWNLOAD_ALL_SK_REPORTS_SESSION,
    {
      testSessionId
    }
  );
};

export const downloadSkReportSession = async (
  testSessionId,
  testDataId,
  blackAndWhite
) => {
  return await getGQLResult(appSyncClient, QUERY_GENERATE_SK_REPORT, {
    testSessionId,
    testDataId,
    blackAndWhite
  });
};

export const downloadHanReports = async testSessionId => {
  return await getGQLResult(
    appSyncClient,
    QUERY_DOWNLOAD_ALL_HAN_REPORTS_SESSION,
    {
      testSessionId
    }
  );
};

export const downloadHanReportSession = async testSessionId => {
  return await getGQLResult(appSyncClient, QUERY_GENERATE_HAN_REPORT, {
    testSessionId
  });
};

export const downloadHanReportPerson = async (testSessionId, personId) => {
  return await getGQLResult(appSyncClient, QUERY_GENERATE_HAN_REPORT, {
    testSessionId,
    personId
  });
};

export const downloadSessionFormQuery = async testSessionId => {
  return await getGQLResult(appSyncClient, QUERY_GENERATE_SESSION_FORM, {
    testSessionId
  });
};

export const exportMTSSession = async testSessionId => {
  const mutation = MUTATION_EXPORT_SESSION_CSV;
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation,
      variables: {
        testSessionId
      },
      update: (cache, { data: { exportTestSession } }) => {
        if (exportTestSession) {
          resolve(exportTestSession);
        }
      }
    });
  });
};

export const MUTATION_EXPORT_SESSION_CSV = gql`
  mutation exportTestSession($testSessionId: ID!) {
    exportTestSession(testSessionId: $testSessionId)
  }
`;

export const QUERY_DOWNLOAD_ALL_SESSION_REPORTS = gql`
  query downloadSessionReports(
    $testSessionId: ID!
    $benchmarkId: ID
    $options: TestSessionReportOptions
  ) {
    downloadSessionReports(
      testSessionId: $testSessionId
      benchmarkId: $benchmarkId
      options: $options
    )
  }
`;

export const QUERY_DOWNLOAD_ALL_HAN_REPORTS_SESSION = gql`
  query downloadHanReports($testSessionId: ID!) {
    downloadHanReports(testSessionId: $testSessionId)
  }
`;

export const QUERY_DOWNLOAD_ALL_SK_REPORTS_SESSION = gql`
  query downloadSkReports($testSessionId: ID!) {
    downloadSkReports(testSessionId: $testSessionId)
  }
`;

export const QUERY_GENERATE_SK_REPORT = gql`
  query generateSkReport(
    $testSessionId: ID!
    $testDataId: ID
    $blackAndWhite: Boolean
  ) {
    generateSkReport(
      testSessionId: $testSessionId
      testDataId: $testDataId
      blackAndWhite: $blackAndWhite
    )
  }
`;

export const QUERY_GENERATE_HAN_REPORT = gql`
  query generateHanReport($testSessionId: ID!, $personId: ID) {
    generateHanReport(testSessionId: $testSessionId, personId: $personId)
  }
`;

export const MUTATION_EXPORT_SESSIONS = gql`
  mutation exportTestSession(
    $from: AWSDate!
    $to: AWSDate!
    $testSetId: ID!
    $entityId: ID!
  ) {
    exportTestSession(
      from: $from
      to: $to
      testSetId: $testSetId
      entityId: $entityId
    )
  }
`;

export const QUERY_GENERATE_SESSION_FORM = gql`
  query printTestSessionForm($testSessionId: ID!) {
    printTestSessionForm(testSessionId: $testSessionId)
  }
`;

export const QUERY_GENERATE_SESSION_REPORT = gql`
  query downloadSessionReport(
    $testDataId: ID!
    $benchmarkId: ID
    $options: TestSessionReportOptions
  ) {
    downloadSessionReport(
      testDataId: $testDataId
      benchmarkId: $benchmarkId
      options: $options
    )
  }
`;

export const MUTATION_MAIL_SESSION_REPORT = gql`
  mutation mailSessionReport(
    $testDataId: ID!
    $options: TestSessionReportOptions
    $benchmarkId: ID
  ) {
    mailSessionReport(
      testDataId: $testDataId
      options: $options
      benchmarkId: $benchmarkId
    )
  }
`;

export const MUTATION_MAIL_ALL_SESSION_REPORT = gql`
  mutation mailSessionAllReportOfTestSession(
    $sessionId: ID!
    $options: TestSessionReportOptions
    $benchmarkId: ID
  ) {
    mailSessionAllReportOfTestSession(
      testSessionId: $sessionId
      options: $options
      benchmarkId: $benchmarkId
    )
  }
`;

export const mailAllSessionReports = async ({
  sessionId,
  benchmarkId,
  options
}) => {
  return new Promise(async resolve => {
    await gqlMutation(appSyncClient, {
      mutation: MUTATION_MAIL_ALL_SESSION_REPORT,
      variables: {
        sessionId,
        options,
        benchmarkId
      },
      update: (cache, { data: { mailSessionAllReportOfTestSession } }) => {
        if (mailSessionAllReportOfTestSession === 'true') {
          resolve(true);
        }
      }
    });
  });
};

export const QUERY_GET_TESTDATA_BY_ENTITY = gql`
  query getTestDataV2OfEntity($entityId: ID!, $benchmarkId: ID) {
    getTestDataV2OfEntity(entityId: $entityId, benchmarkId: $benchmarkId) {
      id
      resultBenchmarkId
      personId
      person {
        id
        firstname
        lastname
      }
      data
      result
      sports
      finished
      finishedPercentage
      testSetVersion
      date_updated
      date_created
      notes {
        id
        linkId
        note
      }
    }
  }
`;

export const QUERY_GET_TESTDATA_BY_GROUP = gql`
  query getTestDataV2OfEntity($entityId: ID!, $benchmarkId: ID) {
    getTestDataV2OfEntity(entityId: $entityId, benchmarkId: $benchmarkId) {
      id
      resultBenchmarkId
      personId
      person {
        id
        firstname
        lastname
      }
      data
      result
      sports
      finished
      finishedPercentage
      testSetVersion
      date_updated
      date_created
      testDataDate
      testSet {
        id
        title
      }
    }
  }
`;
