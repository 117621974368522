import React from 'react';
import { Mutation } from '@apollo/client/react/components';
import Button from 'components/button/Button';
import { MUTATION_UPDATE_BASE_LANGUAGE } from 'services/aws/user-query';
import Icon from 'components/icon/Icon';

const UpdateUserLanguageButton = ({ baseLanguage, currentLanguage, label }) => {
  return (
    <Mutation
      mutation={MUTATION_UPDATE_BASE_LANGUAGE}
      update={(cache, { data: { updateBaseLanguage } }) => {
        if (updateBaseLanguage) {
          window.location.reload();
        }
      }}
    >
      {(updateBaseLanguage, result) => {
        const { data } = result;

        return (
          <Button
            menu
            active={baseLanguage === currentLanguage}
            onClick={e => {
              e.preventDefault();
              updateBaseLanguage({
                variables: {
                  baseLanguage
                }
              });
            }}
          >
            {baseLanguage === currentLanguage && (
              <Icon id="language" strokeColor="color-primary-dark" />
            )}

            {label}
          </Button>
        );
      }}
    </Mutation>
  );
};

export default UpdateUserLanguageButton;
