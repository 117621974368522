import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import {
  downloadHanReportPerson,
  downloadHanReportSession
} from 'services/aws/session-query';
import { pollIsValidUrl } from 'utils/url';
import { openWindowWithLoader } from 'utils/browser';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';

const DownloadHanReportButton = ({ session, person = null }) => {
  const intl = useIntl();
  const {
    uiState,
    authStore: { user }
  } = useContext(StoreContext);

  const downloadHanReport = async newWindow => {
    const openWindow = newWindow;
    try {
      uiState.increasePendingRequest();
      let filenameQuery;
      if (person?.id) {
        filenameQuery = await downloadHanReportPerson(session.id, person.id);
      } else {
        filenameQuery = await downloadHanReportSession(session.id);
      }

      if (filenameQuery) {
        const url = filenameQuery.data.generateHanReport;

        const response = await pollIsValidUrl(url, 120, 1000);

        if (response) {
          openWindow.location = url;
          openWindow.focus();
        }
      }
    } catch (error) {
      if (error.message.indexOf('grade') !== -1) {
        // @ts-ignore
        uiState.showModal({
          title: intl.formatMessage(
            messages.titleSessionHanReportDownloadGradeFailed
          ),
          message: messages.messageSessionHanReportDownloadGradeFailed,
          dismissButton: false,
          type: MODAL_TYPES.WARNING
        });
      } else {
        // @ts-ignore
        uiState.showModal({
          title: intl.formatMessage(
            messages.titleSessionHanReportDownloadFailed
          ),
          message: messages.messageSessionHanReportDownloadFailed,
          dismissButton: false,
          type: MODAL_TYPES.WARNING
        });
      }
    }

    uiState.decreasePendingRequest();
  };

  console.log('DownloadHanReportButton');
  console.log('!is han || ', user.isHanOrg);
  console.log(
    '( user.isHanOrg && ! is klusce) || ',
    user.isHanOrg,
    session.isKlusce
  );
  console.log('is ilike', session.isILike);

  if (!user.isHanOrg || (user.isHanOrg && !session.isKlusce) || session.isILike)
    return <></>;

  console.log('Show DownloadHanReportButton');

  return (
    <Button
      menu
      onClick={() => {
        const newWindow = openWindowWithLoader();
        downloadHanReport(newWindow);
      }}
    >
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemSessionDownloadReport} />
    </Button>
  );
};

export default DownloadHanReportButton;
