import React, { useContext } from 'react';
import Icon from 'components/icon/Icon';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'messages';
import Button from 'components/button/Button';
import { MODAL_TYPES } from 'models/ModalData';
import { StoreContext } from 'index';
import { pollIsValidUrl } from 'utils/url';
import { openWindowWithLoader } from 'utils/browser';
import { downloadExerciseProgram } from 'services/aws/exercises-query';

const DownloadExerciseProgramButton = ({
  programId,
  disabled = false,
  onClick
}) => {
  const intl = useIntl();
  const { uiState } = useContext(StoreContext);

  const downloadReport = async newWindow => {
    const openWindow = newWindow;
    try {
      uiState.increasePendingRequest();
      const url = await downloadExerciseProgram(programId);

      if (url?.data?.downloadExerciseProgram) {
        const response = await pollIsValidUrl(
          url.data.downloadExerciseProgram,
          25,
          2000
        );

        if (response) {
          openWindow.location = url.data.downloadExerciseProgram;
          openWindow.focus();
        }
      }
    } catch (error) {
      // @ts-ignore
      uiState.showModal({
        title: intl.formatMessage(messages.exerciseProgramTitleDownloadFailed),
        message: messages.exerciseProgramMessageDownloadFailed,
        dismissButton: false,
        type: MODAL_TYPES.WARNING
      });
    }

    uiState.decreasePendingRequest();
  };

  return (
    <Button
      menu
      disabled={disabled}
      onClick={() => {
        const newWindow = openWindowWithLoader();
        downloadReport(newWindow);
        onClick && onClick();
      }}
    >
      <Icon id="download" />
      <FormattedMessage {...messages.menuItemExerciseDownloadProgram} />
    </Button>
  );
};

export default DownloadExerciseProgramButton;
