import React from 'react';
import { Button } from 'components/button';
import Icon from 'components/icon/Icon';
import messages from 'messages';
import { FormattedMessage } from 'react-intl';

const GenerateMagicLinkButton = ({ onClick, children, person, ...props }) => {
  if (!person.email && !person.athleteEmail)
    return (
      <Button {...props} disabled>
        <Icon id="link" />
        <FormattedMessage {...messages.generateMagicLinkButtonDisabled} />
      </Button>
    );
  return (
    <Button {...props} onClick={onClick}>
      <Icon id="link" />
      <FormattedMessage {...messages.generateMagicLinkButton} />
    </Button>
  );
};

export default GenerateMagicLinkButton;
