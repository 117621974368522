import { useContext, useEffect } from 'react';
import { StoreContext } from 'index';
import ExercisesListVirtualized from 'containers/partials/lists/ExercisesListVirtualized';
import { useStore } from 'stores/Store';
import { useParams } from 'react-router-dom';
import ExerciseFilterHeader from 'containers/pages/exercises/exercises/ExerciseFilterHeader';
import ExercisesFetcher from 'containers/pages/exercises/exercises/ExercisesFetcher';
import Loader from 'components/loader/Loader';

const ExercisesPanel = ({ onClick, onPreview }) => {
  const {
    authStore: { user }
  } = useContext(StoreContext);

  const { entityId, exerciseId } = useParams();
  const exercises = useStore(state => state.exercises);
  const selectedExercises = useStore(state => state.selectedExercises);
  const resetSelectedExercises = useStore(
    state => state.resetSelectedExercises
  );
  const selectExercises = useStore(state => state.selectExercises);
  const filter = useStore(state => state.exercisesFilter);
  const loading = useStore(state => state.loadingExercises);

  useEffect(() => {
    console.log('reset the selected', selectedExercises);
    resetSelectedExercises();
  }, []);

  return (
    <ExercisesFetcher entityId={entityId}>
      {({ ...fetcherProps }) => (
        <>
          <ExerciseFilterHeader filterByOrg={!user.isGSVOrg} />

          {loading && <Loader />}

          <ExercisesListVirtualized
            entityId={entityId}
            checkable
            exercises={exercises}
            {...fetcherProps}
            activeId={exerciseId}
            onPreview={exercise => onPreview && onPreview(exercise)}
            enablePreviewModal={false}
            showTemplateCount={user.isGSVOrg}
            showLevel={!user.isGSVOrg}
            selectedExercises={selectedExercises}
            onClick={id => {
              onClick && onClick(id);
            }}
            onSelect={exercise => {
              selectExercises(exercise);
            }}
          />
        </>
      )}
    </ExercisesFetcher>
  );
};

export default ExercisesPanel;
