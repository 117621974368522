import React from 'react';
import classNames from 'classnames';
import MenuBody from './MenuBody';
import Menu from './Menu';

import Downshift from 'downshift';
import { cleanFalsy } from 'utils/object';

class MenuWrapper extends React.Component {
  render() {
    const {
      trigger,
      header,
      isSelect,
      asModal,
      organisation,
      extraClassNames,
      onStateChange
    } = this.props;

    if (!this.props.children) return null;
    const children = cleanFalsy(this.props.children);
    if (Object.keys(children).length === 0) {
      return null;
    }
    return (
      <Dropdown
        organisation={organisation}
        asModal={asModal}
        isSelect={isSelect}
        items={this.props.children}
        trigger={trigger}
        extraClassNames={extraClassNames}
        header={header}
        onStateChange={onStateChange}
      />
    );
  }
}

const renderChildren = (children, closeMenu) => {
  return React.Children.map(children, child => {
    if (child) {
      return React.cloneElement(child, {
        onClick: () => {
          if (child.props.onClick) {
            child.props.onClick();
          }
          closeMenu();
        }
      });
    }
  });
};

const Dropdown = ({
  items,
  trigger,
  header,
  isSelect,
  asModal,
  extraClassNames,
  organisation,
  onStateChange
}) => (
  <React.Fragment>
    <Downshift
      onStateChange={onStateChange}
      itemToString={item => (item ? item.key : '')}
    >
      {({
        isOpen,
        closeMenu,
        getToggleButtonProps,
        getMenuProps,
        selectedItem,
        ...rest
      }) => (
        <div
          className={classNames(
            'c-menu__wrapper c-panel__title-button',
            extraClassNames,
            {
              'c-menu__wrapper--select': isSelect,
              'c-menu__wrapper--as-modal': asModal,
              'c-menu__wrapper--organisation': organisation
            }
          )}
        >
          {trigger &&
            React.cloneElement(trigger, { ...getToggleButtonProps() })}

          {isOpen && items && (
            <Menu organisation={organisation} {...getMenuProps({ isOpen })}>
              {header && React.cloneElement(header)}
              <MenuBody>{renderChildren(items, closeMenu)}</MenuBody>
            </Menu>
          )}
        </div>
      )}
    </Downshift>
    {asModal && <div className="c-menu__wrapper-overlay" />}
  </React.Fragment>
);

export default MenuWrapper;
