import React, { useContext } from 'react';
import Modal from 'components/modal/Modal';
import { CardHeader, CardHeaderTitle } from 'components/card/card-header';
import { ButtonsGroup, Button } from 'components/button';
import { CardFooter, CardBody } from 'components/card';
import { FormattedMessage } from 'react-intl';
import messages from 'messages';
import { StoreContext } from 'index';
import { useStore } from 'stores/Store';

const ErrorModal = props => {
  const { uiState } = useContext(StoreContext);
  const {
    modalData: { title, message = {}, dismissLabel, dismissHandler }
  } = props;

  const closeHandler = () => {
    resetAuthState();
    uiState.hideErrorModal();

    dismissHandler && dismissHandler();
  };

  const resetAuthState = () => {
    useStore.setState({ authorized: false });
  };

  console.log(message);
  return (
    <Modal card alert autoHeight isOpen={true} onClose={closeHandler}>
      <CardHeader modal>
        <CardHeaderTitle>
          {title && typeof title === 'object' ? (
            <FormattedMessage {...title} />
          ) : title && typeof title === 'string' ? (
            <>{title}</>
          ) : (
            <FormattedMessage {...messages.errorModalTitle} />
          )}
        </CardHeaderTitle>
      </CardHeader>
      <CardBody modal>
        {message && typeof message === 'object' ? (
          <p>
            <FormattedMessage {...message} />
          </p>
        ) : message && typeof message === 'string' ? (
          <p>{message}</p>
        ) : (
          'An error occurred.'
        )}
      </CardBody>
      <CardFooter modal>
        <ButtonsGroup>
          <Button secondary onClick={closeHandler}>
            {dismissLabel ? (
              dismissLabel
            ) : (
              <FormattedMessage {...messages.buttonClose} />
            )}
          </Button>
        </ButtonsGroup>
      </CardFooter>
    </Modal>
  );
};

export default ErrorModal;
